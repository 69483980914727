<template>
  <div class="dialog-size-md mp-international-publishing-status-dialog">
    <div class="modal-body">
      <h2>
        {{ $t('DESIGNS.DETAILS.MP_INTPUB.HEADING') }}
      </h2>
      <p class="text-grey">
        {{ $t('DESIGNS.DETAILS.MP_INTPUB.SUBHEADING') }}
      </p>

      <div v-if="idea.approvalPending" class="color-box">
        {{ $t('DESIGNS.DETAILS.MP_KIDS_INFO') }}
      </div>

      <InternationalPublishingStatusTable
        :getDeeplinkForLocale="getDeeplinkForLocale"
        displayUnavailableDeeplinks
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mpUrlService from '@/api/mpUrlService/mpUrlService';
import InternationalPublishingStatusTable from './InternationalPublishingStatusTable.vue';

export default {
  name: 'MpInternationalPublishingStatusDialog',
  props: ['modalInstance', 'data'],
  components: {
    InternationalPublishingStatusTable,
  },
  computed: {
    ...mapState({
      idea: (state) => state.idea.current,
    }),
  },
  methods: {
    getDeeplinkForLocale(locale) {
      return mpUrlService.getMpDeeplinkForIdea(this.idea.id, {
        locale,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.mp-international-publishing-status-dialog {
  .modal-body {
    & > h2 {
      margin: 0;
    }

    & > p {
      margin: 12px 0 16px 0;
    }
  }
}

.loading-wrapper {
  margin: 48px 0;
}
</style>
