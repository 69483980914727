<template>
  <div class="design-card-view">
    <div class="card-container col-xs-1 upload-tile">
      <a
        href="#"
        id="upload-btn"
        class="upload-button"
        @click.prevent="$emit('upload')"
      >
        <div>
          <Icon icon="plus"></Icon>
          <h4>{{ $t('DESIGNS.LIST.UPLOAD_DESIGN') }}</h4>
          <h4>{{ $t('DESIGNS.UPLOAD.DROP_FILES') }}</h4>
        </div>
        <small
          class="text-xs"
          v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"
        ></small>
      </a>
    </div>
    <div class="card-container col-xs-1" v-for="idea in ideas" :key="idea.id">
      <IdeaCard
        :idea="idea"
        @toggleSelection="toggleIdeaSelection(idea)"
        @edit="$emit('edit', idea)"
      />
    </div>
    <div
      v-for="index in placeholders"
      :key="`placeholder-${index}`"
      class="card-container col-xs-1 card-placeholder"
    >
      <div class="card">
        <div class="preview-image"></div>
        <div class="card-footer"></div>
      </div>
    </div>
    <div
      v-for="(x, index) in 5"
      :key="index"
      class="card-container col-xs-1 card-container-dummy no-margin"
      style="height: 1px; visibility: hidden"
    ></div>
  </div>
</template>

<script>
import IdeaCard from './IdeaCard.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'IdeaCardView',
  components: {
    IdeaCard,
  },
  data() {
    return {
      placeholders: 0,
    };
  },
  mounted() {
    this.calcPlaceholders();
    window.addEventListener('resize', this.calcPlaceholders);
  },
  computed: {
    ...mapGetters({
      getSetting: 'settings/getSetting',
    }),
    ...mapState({
      ideas: (state) => state.ideas.list,
      navbarExpanded: (state) => state.application.navbarExpanded,
    }),
    pageSize() {
      return this.getSetting('DESIGNS_PAGE_SIZE');
    },
    ideaCount() {
      return this.ideas.length;
    },
  },
  watch: {
    navbarExpanded() {
      this.calcPlaceholders();
    },
    ideaCount() {
      this.calcPlaceholders();
    },
  },
  methods: {
    ...mapActions({
      toggleIdeaSelection: 'ideas/toggleIdeaSelection',
    }),
    calcPlaceholders: debounce(function () {
      if (!(this.pageSize - this.ideaCount > 0)) {
        this.placeholders = 0;
        return;
      }

      let tilesLeftOnPage = this.pageSize - this.ideaCount;

      let tilesPerPage;
      if (this.$el.clientWidth < 1060) {
        tilesPerPage = 3;
      } else {
        tilesPerPage = 4;
      }

      this.placeholders = Math.min(
        tilesLeftOnPage,
        (tilesLeftOnPage % tilesPerPage) + tilesPerPage
      );
    }, 200),
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calcPlaceholders);
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

.design-card-view {
  padding: 0 12px;
}
.card-container {
  flex: 1 1 300px;
  min-width: 300px;

  @include small-desktop {
    flex: 1 1 260px;
    min-width: 260px;
  }
}

.card-placeholder {
  display: flex;

  & > div {
    flex-grow: 1;
    border: 2px dashed #c5c5c5;
    border-radius: 4px;

    * {
      visibility: hidden !important;
    }
  }

  .preview-image:before {
    content: '';
    display: block;
    padding-top: math.div(100, math.div(300, 350)) * 1%;
  }
}

.upload-tile {
  display: flex;
  flex-direction: column;

  & > a {
    background-color: #fff;
    border-radius: 4px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border: 2px solid $pa-color-main;

    &:hover {
      border-color: $pa-color-main-dark;
    }

    & > div {
      padding: 24px;

      .icon {
        color: $pa-color-main-dark;
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }

      h4 {
        margin: 4px 0 0 0;
      }
    }

    & > small {
      position: absolute;
      padding: 24px;
      bottom: 0;
      left: 0;
      width: 100%;
      color: $grey60;

      :deep(a) {
        @extend .link-main;
      }
    }
  }
}
</style>
