<template>
  <div class="account">
    <div class="account-menu overflow-y">
      <h1>
        {{ $t('NAV.ACCOUNT') }}
      </h1>
      <ul>
        <li
          v-for="page in pages"
          :key="page.key"
          :class="{ active: page === currentPage }"
        >
          <router-link :to="`/account/${page.key.toLowerCase()}`">
            {{ $t(page.name) }}
          </router-link>
          <div class="alert-badge" v-if="alertForPage(page)">
            <CircleBadge :color="alertForPage(page).severity" />
          </div>
        </li>
      </ul>
      <AccountNavBottom />
    </div>
    <div class="account-subpage overflow-y">
      <div v-if="initialized && currentPage">
        <AccountWarnings />
        <component class="account-content" :is="currentPage.component" />
      </div>

      <LoadingHeart v-else />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import accountService from '@/api/accountService/accountService';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import Financial from './financial/Financial.vue';
import Security from './security/Security.vue';
import Taxation from './taxation/Taxation.vue';
import Newsletter from './newsletter/Newsletter.vue';
import Personal from './personal/Personal.vue';
import DeviceConnection from './deviceConnection/DeviceConnection.vue';
import Beta from './beta/Beta.vue';
import AccountWarnings from './AccountWarnings.vue';
import CircleBadge from 'src/app/components/badge/CircleBadge.vue';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import AccountNavBottom from './AccountNavBottom.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'Account',
  components: {
    Financial,
    Security,
    Taxation,
    Newsletter,
    Personal,
    ProgressButton,
    DeviceConnection,
    Beta,
    LoadingHeart,
    AccountWarnings,
    CircleBadge,
    AccountNavBottom,
  },
  data() {
    return {
      platform: SPRD.PLATFORM,
      initialized: false,
      pages: [
        {
          key: 'PERSONAL',
          name: 'ACCOUNT.PERSONAL.HEADING',
          component: Personal,
        },
        {
          key: 'SECURITY',
          name: 'ACCOUNT.SECURITY.HEADING',
          component: Security,
        },
        {
          key: 'NEWSLETTER',
          name: 'ACCOUNT.NEWSLETTER.HEADING',
          component: Newsletter,
          initialize: this.fetchNewsletterData,
        },
        {
          key: 'FINANCIAL',
          name: 'ACCOUNT.FINANCE.HEADING',
          component: Financial,
          initialize: this.fetchPaymentInfo,
        },
        {
          key: 'TAXATION',
          name: 'ACCOUNT.TAXATION.HEADING',
          component: Taxation,
          initialize: this.fetchTaxationData,
        },
        {
          key: 'DEVICECONNECTION',
          name: 'CONNECT_DEVICES.HEADING',
          component: DeviceConnection,
        },
        // {
        //   key: 'BETA',
        //   name: 'ACCOUNT.BETA.HEADING',
        //   component: Beta,
        // },
      ],
      currentPage: null,
    };
  },
  async created() {
    this.initPage();
  },
  watch: {
    $route(newVal) {
      if (newVal.name === 'partnerarea.account') {
        this.initPage();
      }
    },
  },
  async beforeRouteUpdate(to, from) {
    return unsavedChangesService.onRouteUpdate(to, from);
  },
  computed: {
    ...mapState({
      softCheckFailed: (state) => state.account.softCheckFailed,
    }),
    ...mapGetters({
      accountAlerts: 'account/alerts',
    }),
  },
  methods: {
    ...mapActions({
      fetchNewsletterData: 'userNewsletter/fetchNewsletterData',
      fetchPaymentInfo: 'userPaymentInfo/fetchPaymentInfo',
      fetchTaxationData: 'userTaxation/fetchTaxationData',
    }),
    async initPage() {
      this.initialized = false;

      const data = await accountService.getData();
      this.$store.commit('account/setAccountData', { data });

      this.currentPage = this.pages.find(
        (page) => page.key.toLowerCase() === this.$route.params.subpage
      );

      if (!this.currentPage) {
        this.$router.push({
          name: 'partnerarea.account',
          params: { subpage: 'personal' },
        });
      } else if (this.currentPage.initialize) {
        await this.currentPage.initialize();
      }

      this.initialized = true;
      analytics.logScreenView(
        `partnerarea.account-${this.$route.params.subpage}`
      );
    },
    alertForPage(page) {
      return this.accountAlerts.find(
        (alert) =>
          alert.routeOnClick?.route === 'partnerarea.account' &&
          alert.routeOnClick?.params?.subpage === page.key.toLowerCase()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
.account {
  position: relative;
  display: flex;

  .account-menu {
    background-color: $grey0;
    width: 280px;
    flex-shrink: 0;
    padding: 24px 0;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0 24px 16px 24px;
    }

    ul {
      margin: 0 0 auto 0;
      padding: 0;
    }

    li {
      position: relative;

      &.active {
        background-color: $grey5;
        font-weight: 700;
      }

      a {
        display: block;
        padding: 8px 24px;
      }
    }

    .alert-badge {
      position: absolute;
      top: 0;
      height: 100%;
      right: 16px;
      display: flex;
      align-items: center;

      .badge {
        width: 12px;
        height: 12px;
      }
    }

    .logout {
      margin: auto 0 0 24px;
    }
  }

  .account-subpage {
    flex-grow: 1;
    padding: 24px 24px 0 24px;

    & > div {
      max-width: 1400px;
      margin: 0 auto;
    }

    .color-box {
      margin-bottom: 10px;
    }

    .taxation-warning {
      .link:hover,
      .link:focus {
        color: darken(#fff, 5%);
      }
    }
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.account {
  .field-info {
    padding-bottom: 10px;
    position: relative;
  }

  .error-info {
    position: absolute;
    top: 4px;
  }

  .input-clear-button {
    position: absolute;
    top: 9px;
    right: 9px;
  }

  .box {
    background: $grey0;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 20px;

    .box-header {
      h2 {
        margin: 0 0 24px 0;
      }
    }

    .box-content {
      position: relative;

      h4 {
        margin: 0 0 8px 0;
      }
    }
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -24px -24px 0;

    & > * {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 24px 24px 0;
    }
  }
}
</style>
