export const DIGITAL_PRODUCTS_STATES = {
  UNPUBLISHED: {
    key: 'UNPUBLISHED',
    name: 'DIGITAL_PRODUCTS.STATUS.UNPUBLISHED.NAME',
    description: 'DIGITAL_PRODUCTS.STATUS.UNPUBLISHED.DESCRIPTION',
    class: 'unpublished',
  },
  REVIEW: {
    key: 'REVIEW',
    name: 'DIGITAL_PRODUCTS.STATUS.REVIEW.NAME',
    description: 'DIGITAL_PRODUCTS.STATUS.REVIEW.DESCRIPTION',
    class: 'review',
  },
  PUBLISHED: {
    key: 'PUBLISHED',
    name: 'DIGITAL_PRODUCTS.STATUS.PUBLISHED.NAME',
    description: 'DIGITAL_PRODUCTS.STATUS.PUBLISHED.DESCRIPTION',
    class: 'published',
  },
  REJECTED: {
    key: 'REJECTED',
    name: 'DIGITAL_PRODUCTS.STATUS.REJECTED.NAME',
    description: 'DIGITAL_PRODUCTS.STATUS.REJECTED.DESCRIPTION',
    class: 'rejected',
  },
};

export const DIGITAL_PRODUCTS_UPDATE_STATES = {
  REVIEW: {
    key: 'REVIEW',
    name: 'Review',
  },
  REJECTED: {
    key: 'REJECTED',
    name: 'Rejected',
  },
  NONE: {
    key: 'NONE',
    name: 'None',
  },
};

export function getDigitalProductState(digitalProduct) {
  if (digitalProduct.hasApprovedRevision && digitalProduct.shopIds.length) {
    return DIGITAL_PRODUCTS_STATES.PUBLISHED;
  } else if (
    digitalProduct.hasApprovedRevision &&
    !digitalProduct.shopIds.length
  ) {
    return DIGITAL_PRODUCTS_STATES.UNPUBLISHED;
  } else if (digitalProduct.latestRevisionPublishState === 'REJECTED') {
    return DIGITAL_PRODUCTS_STATES.REJECTED;
  } else if (
    !digitalProduct.hasApprovedRevision &&
    ['PENDING_REVIEW', 'IN_REVIEW'].includes(
      digitalProduct.latestRevisionPublishState
    )
  ) {
    return DIGITAL_PRODUCTS_STATES.REVIEW;
  } else {
    return DIGITAL_PRODUCTS_STATES.UNPUBLISHED;
  }
}

export function getDigitalProductUpdateState(digitalProduct) {
  if (
    digitalProduct.hasApprovedRevision &&
    ['PENDING_REVIEW', 'IN_REVIEW'].includes(
      digitalProduct.latestRevisionPublishState
    )
  ) {
    return DIGITAL_PRODUCTS_UPDATE_STATES.REVIEW;
  } else if (
    digitalProduct.hasApprovedRevision &&
    digitalProduct.latestRevisionPublishState === 'REJECTED'
  ) {
    return DIGITAL_PRODUCTS_UPDATE_STATES.REJECTED;
  } else {
    return DIGITAL_PRODUCTS_UPDATE_STATES.NONE;
  }
}
