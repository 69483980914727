<template>
  <div class="publishing-sellables-dialog">
    <div class="heading">
      <h1>Product properties</h1>
      <div class="actions">
        <button class="link-main" @click="modalInstance.dismiss">Close</button>
        <ProgressButton
          class="btn btn-primary icon-btn"
          :label="$t('GENERAL.SAVE')"
          icon="savedisk"
          :disabled="!temporaryComposition"
          :onClick="save"
        />
      </div>
    </div>
    <SellableDefaultValueControl
      :composition="previewComposition"
      @onSelectHotspot="setHotspot"
      @onSelectColor="setColor"
    />
    <div class="sellable-heading">
      <div>
        <SellableCategoryBreadcrumb
          v-if="selectedCategory"
          :selectedCategory="selectedCategory"
          @selectCategory="selectCategory"
          breadCrumbOnly
          class="breadcrumb"
        />
        <h3>{{ heading }}</h3>
      </div>
      <div>
        <p>{{ sellables.length }} Product(s)</p>
      </div>
    </div>
    <div class="wrapper-content">
      <div>
        <SellableCategoryTree
          :selectedCategory="selectedCategory"
          @selectCategory="selectCategory"
        />
      </div>
      <div class="sellables">
        <SellableList :sellables="sellables" />
        <div v-if="deactivatedSellables.length" class="unavailable">
          <h3>Deactivated products</h3>
          <SellableList :sellables="deactivatedSellables" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import compositionHelper from '@/composition/compositionHelper';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import localeService from '@/localeService/localeService';
import SellableCategoryTree from './sellables/SellableCategoryTree.vue';
import SellableCategoryBreadcrumb from './sellables/SellableCategoryBreadcrumb.vue';
import SellableList from './sellables/SellableList.vue';
import SellableDefaultValueControl from './sellables/SellableDefaultValueControl.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';

export default {
  name: 'PublishingSellablesDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object },
  },
  components: {
    SellableCategoryTree,
    SellableCategoryBreadcrumb,
    SellableList,
    SellableDefaultValueControl,
    ProgressButton,
  },
  data() {
    return {
      selectedCategory: null,
      getProductImage: compositionHelper.getProductImage,
      locale: localeService.getLocale(),
      temporaryComposition: null,
    };
  },
  async created() {},
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
      categories: (s) => s.publishingCore.categories,
    }),
    previewComposition() {
      return this.temporaryComposition || this.composition;
    },
    heading() {
      if (this.selectedCategory) {
        return this.selectedCategory.names[this.locale];
      } else {
        return 'All Products';
      }
    },
    sellables() {
      const productTypeIds = compositionCategoryHelper.getAllProductTypeIds(
        this.selectedCategory || this.categories
      );

      const sellableList = this.previewComposition.sellables;

      return sellableList.filter((sellable) =>
        productTypeIds.includes(sellable.productTypeId)
      );
    },
    deactivatedSellables() {
      const productTypeIds = compositionCategoryHelper.getAllProductTypeIds(
        this.selectedCategory || this.categories
      );

      const sellablesWithError =
        compositionHelper.getAllDeactivatedSellablesFlat(this.composition);

      return sellablesWithError.filter((sellable) =>
        productTypeIds.includes(sellable.productTypeId)
      );
    },
  },
  methods: {
    ...mapActions({
      updateCompositionSellables: 'composition/updateCompositionSellables',
    }),
    selectCategory(category) {
      this.selectedCategory = category;
    },
    async setHotspot(hotspot) {
      await this.updateComposition({ hotspot, preview: true });
    },
    async setColor(color) {
      await this.updateComposition({ color, preview: true });
    },
    async save() {
      await this.updateComposition();
      this.modalInstance.close();
    },
    async updateComposition({ hotspot, color, preview } = {}) {
      const data = await this.updateCompositionSellables({
        defaultHotspot:
          hotspot ||
          compositionHelper.getDefaultHotspot(this.previewComposition),
        defaultAppearanceGroup:
          color ||
          compositionHelper.getDefaultAppearanceGroup(this.previewComposition)
            ?.id,
        preview,
      });
      this.temporaryComposition = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.publishing-sellables-dialog {
  display: flex;
  flex-direction: column;
}

.heading {
  padding: 48px 48px 24px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  margin: 0;
}

.actions {
  display: flex;
  gap: 16px;

  .btn {
    padding: 8px 16px;
  }
}

.subheading {
  color: $grey60;
  margin: 8px 0 0 0;
}

.wrapper-content {
  display: flex;
  overflow: hidden;

  & > div:first-child {
    width: 220px;
    padding: 0 0 0 48px;
  }
}

.sellable-heading {
  padding: 24px 48px 16px 48px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid $grey15;

  h3 {
    margin: 0;
  }

  .breadcrumb {
    margin-bottom: 16px;
  }

  p {
    margin: 0;
  }
}

.sellables {
  flex: 1;
  padding: 0 48px 0 24px;
}

.unavailable {
  h3 {
    margin: 0 0 16px 0;
  }
}
</style>
