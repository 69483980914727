<template>
  <div class="publishing-products">
    <div class="publishing-image-wrapper">
      <div
        class="publishing-image design-checked-background"
        :class="{ dark: composition.properties.darkBackground }"
      >
        <SprdImage
          :src="`/image-server/v1/designs/${composition.mainDesign.id},width=300,height=300.png`"
        />
        <button
          class="dark-background-toggle"
          @click.prevent="toggleBackgroundColor"
        >
          <Icon
            icon="background-color"
            :class="{
              'rotate-icon-180': composition.properties.darkBackground,
            }"
          />
        </button>
      </div>
    </div>
    <div class="publishing-properties">
      <ConfigList class="data">
        <ul>
          <li>
            <div>
              <p>Products generated</p>
              <p class="text-sm text-grey">with our new automated alogritm</p>
            </div>
            <div>{{ composition.sellables.length }}</div>
          </li>
          <li>
            <div>
              <p>Default color</p>
              <p class="text-sm text-grey">best contrast we could find</p>
            </div>
            <div v-if="defaultAppearanceGroup">
              <ColorCircle
                :color="defaultAppearanceGroup.color"
                :name="defaultAppearanceGroup.name"
                :size="20"
              />
              {{ defaultAppearanceGroup.name }}
            </div>
            <div v-else>automatic</div>
          </li>
          <li>
            <div>
              <p>Default position</p>
              <p class="text-sm text-grey">on apparel products</p>
            </div>
            <div>
              {{ composition.defaultValues.defaultHotspot }}
            </div>
          </li>
        </ul>
      </ConfigList>
      <button class="link-main edit-btn" @click="openSellablesDialog">
        Edit product properties
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ConfigList from 'src/app/components/publishing/ConfigList.vue';
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';
import SprdImage from '@/image/SprdImage.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import PublishingSellablesDialog from './PublishingSellablesDialog.vue';

export default {
  name: 'PublishingProducts',
  components: {
    ConfigList,
    ColorCircle,
    SprdImage,
  },
  props: {},
  data() {
    return {
      mpSelected: false,
    };
  },
  created() {},
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({
      defaultAppearanceGroup: 'composition/defaultAppearanceGroup',
    }),
  },
  methods: {
    ...mapMutations({
      toggleBackgroundColor: 'composition/toggleBackgroundColor',
    }),
    ...mapActions({}),
    openSellablesDialog() {
      dialogService.openDialog(PublishingSellablesDialog, null, {
        dialogContentClass: 'dialog-sellables',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.publishing-products {
  display: flex;

  .publishing-image-wrapper {
    max-width: 30%;
    min-width: 200px;
    margin-right: 24px;
  }

  .publishing-image {
    aspect-ratio: 1;
    flex: 1;
    padding: 24px;
    width: 100%;
    margin: 0 24px 0 0;
    border: 1px solid $grey15;
    border-radius: 8px;
    position: relative;

    :deep(img) {
      width: 100%;
      height: 100%;
    }

    .dark-background-toggle {
      position: absolute;
      left: 16px;
      bottom: 16px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }

    &.dark .dark-background-toggle {
      color: $grey5;
    }
  }

  .data {
    flex-grow: 1;
  }
}

.publishing-properties {
  width: 100%;

  & > div {
    padding: 0 0 16px 0;
    border-bottom: 1px solid $grey10;

    p {
      margin: 0;
    }
  }

  .edit-btn {
    margin: 16px 0 0 0;
  }
}

.sellables-link {
  font-weight: normal;
  color: $grey80;
  .icon {
    margin-left: 8px;
  }
}
</style>

<style lang="scss">
.dialog-base .dialog-content.dialog-sellables {
  min-height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 0;
}
</style>
