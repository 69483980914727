<template>
  <div class="publishing-hotspot-selection">
    <div class="header-text">
      <h4>Default Color</h4>
      <p>Colors with successful automated contrast checks.</p>
    </div>
    <ul>
      <li
        v-for="appearanceGroup in enabledAppearanceGroups"
        :key="appearanceGroup.id"
      >
        <ColorCircle
          :color="appearanceGroup.uiColor"
          :name="appearanceGroup.name"
          :size="32"
          @click="selectAppearanceGroup(appearanceGroup.id)"
          :active="appearanceGroup.id === currentAppearanceGroup?.id"
        />
      </li>
    </ul>
    <div class="disabled-colors" v-if="disabledAppearanceGroups.length">
      <div class="header-text">
        <h4>Deactivated Color</h4>
        <p>Colors that didn't pass the automated contrast check.</p>
      </div>
      <ul>
        <li
          v-for="appearanceGroup in disabledAppearanceGroups"
          :key="appearanceGroup.id"
        >
          <ColorCircle
            :color="appearanceGroup.uiColor"
            :name="appearanceGroup.name"
            :size="32"
            :deactivated="true"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import compositionHelper from '@/composition/compositionHelper';
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';

export default {
  name: 'SellableColorSelection',
  props: {
    composition: {
      type: Object,
    },
  },
  components: {
    ColorCircle,
  },
  async created() {},
  computed: {
    ...mapState({
      categories: (s) => s.publishingCore.categories,
    }),
    appearanceGroups() {
      return compositionCategoryHelper.getAllAvailableAppearanceGroups(
        this.categories
      );
    },
    enabledAppearanceGroups() {
      return this.appearanceGroups.filter(
        (appearanceGroup) =>
          !compositionHelper
            .getDisabledAppearanceGroupIds(this.composition)
            .includes(appearanceGroup.id)
      );
    },
    currentAppearanceGroup() {
      return compositionHelper.getDefaultAppearanceGroup(this.composition);
    },
    disabledAppearanceGroups() {
      return this.appearanceGroups.filter((appearanceGroup) =>
        compositionHelper
          .getDisabledAppearanceGroupIds(this.composition)
          .includes(appearanceGroup.id)
      );
    },
  },
  methods: {
    selectAppearanceGroup(id) {
      this.$emit('onSelect', id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';
@import 'src/scss/styleguide/type';

.publishing-hotspot-selection {
  .header-text {
    margin: 0 0 16px 0;

    h4 {
      margin: 0;
    }

    p {
      margin: 8px 0 0 0;
      color: $grey60;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      button {
        border-radius: 22px;
        border: 1px solid $grey30;
        font-weight: normal;
        color: $grey90;
        margin: 0 16px 0 0;

        &:hover,
        &.active {
          border-color: $pa-color-main;
          color: $pa-color-main;
        }
      }
    }
  }

  .disabled-colors {
    margin: 24px 0 0 0;
  }
}
</style>
