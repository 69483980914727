<template>
  <div class="dynamic-event" v-if="currentEvent">
    <div class="header">
      <h3>
        {{ currentEvent.summary }}
      </h3>
    </div>
    <div>
      <p v-html="currentEvent.description"></p>
    </div>
  </div>
</template>

<script>
import parse from 'date-fns/parseISO';
import format from 'date-fns/format';
import { mapGetters } from 'vuex';
import apiClient from '@/api/client/apiClient';

export default {
  name: 'Events',
  data() {
    return {
      currentEvent: null,
    };
  },
  computed: {
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
  },
  created() {
    apiClient
      .request({
        method: 'GET',
        url: 'https://www.googleapis.com/calendar/v3/calendars/phqsiqeosfrhrshk66sc7mabuk@group.calendar.google.com/events?key=AIzaSyA9vdMM05dmAHgb5iGzvQM_9KcZpxN6y-w',
        withCredentials: false,
        params: {
          locale: null,
        },
      })
      .then((res) => {
        const today = parse(format(new Date(), 'yyyy-MM-dd'));
        const currentEvent = res.data.items.find((item) => {
          return (
            parse(item.start.date) <= today &&
            today < parse(item.end.date) &&
            this.checkIfEventIsForUserType(item)
          );
        });

        if (currentEvent && currentEvent.description) {
          currentEvent.description = currentEvent.description.replace(
            /">/g,
            '" target="_blank">'
          );
          this.currentEvent = currentEvent;
        }
      });
  },
  methods: {
    checkIfEventIsForUserType(item) {
      const typeFits =
        ['SHOP', 'MP'].includes(item.location) &&
        item.location === this.partnerType;
      const noValidTypeSpecified = !['SHOP', 'MP'].includes(item.location);
      const isMultiPosUser =
        ['SHOP', 'MP'].includes(item.location) && this.partnerType === 'BOTH';

      return typeFits || noValidTypeSpecified || isMultiPosUser;
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.dynamic-event {
  background-color: $grey0;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 24px;

  :deep(a) {
    @extend .link-main;
  }
}
</style>
