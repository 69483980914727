<!-- Root mount point for the shop area -->
<!-- Unfortunately, this uses a lot of global css from src/scss/pos/_partnerarea.scss and src/scss/pos/_shop-area-base.scss -->

<template>
  <div
    v-if="shopBootstrapped"
    class="shop-area"
    :class="{ 'is-fullscreen': fullScreen }"
  >
    <ShopSetupWizard v-if="showShopSetupWizard" />
    <div v-else class="row content-wrapper">
      <ShopNavigation @navigationUpdate="onNavigationUpdate" />

      <div
        class="context"
        :class="{
          'is-fullscreen': fullScreen,
          'no-livepreview': !livePreviewVisible,
        }"
      >
        <ActivationBar v-if="settings.hidden" />
        <router-view
          class="settings clearfix"
          :class="{ hidden: livePreviewVisible }"
          name="context@shop-area"
        ></router-view>
        <ShopActionBar />
        <ShopLivePreview />
      </div>
    </div>
  </div>
</template>

<script>
import ActivationBar from './activation/ActivationBar.vue';
import ShopActionBar from './actionBar/ShopActionBar.vue';
import ShopLivePreview from './livePreview/ShopLivePreview.vue';
import ShopNavigation from './navigation/ShopNavigation.vue';
import ShopSetupWizard from './setupWizard/ShopSetupWizard.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import dialogService from '@/dialogs/wrapper/dialogService';
import ShopActivationDialog from 'src/app/partnerarea/pos/shop/activation/ActivationDialog.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

export default {
  name: 'ShopArea',
  mixins: [SettingsData],
  components: {
    ActivationBar,
    ShopActionBar,
    ShopLivePreview,
    ShopNavigation,
    ShopSetupWizard,
  },
  data() {
    return {
      shopBootstrapped: false,
      customSaving: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (this.customSaving) {
      return next();
    }

    if (this.$store.getters['shop/shopSavableDirty']) {
      return next(false);
    }

    if (to.params.shopId !== from.params.shopId) {
      // switching shops
      this.beforeShopAreaLeave(to, from, next);
    } else {
      // shop area internal page routing
      this.$store.commit('shop/validateShopSavableSubset', from.name);
      if (
        !from.params.skipValidation &&
        this.$store.getters['shop/hasShopSavableValidationErrors']
      ) {
        return false;
      } else {
        next();
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.customSaving) {
      return next();
    }

    this.beforeShopAreaLeave(to, from, next);
  },
  watch: {
    '$route.params.shopId': {
      handler(shopId, oldShopId) {
        if (shopId && shopId !== oldShopId) {
          this.init();
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.initCurrentShop();

      if (this.$route.name === 'shop-area') {
        this.$router.push({ name: 'shop-area.staticShopPreview' });
      }
    },
    async initCurrentShop() {
      await this.$store.dispatch('shop/bootstrapShopSavable', {
        userId: this.$store.state.user.data.id,
        shopId: this.$route.params.shopId,
      });

      this.shopBootstrapped = true;
    },
    async deactivatedShopReminder(to, from, next) {
      try {
        const data = await shopAPI.getShop(
          this.$store.state.user.data.id,
          from.params.shopId
        );
        if (
          !from.meta.hasUnsavedComponents &&
          data &&
          data.hidden &&
          !this.$store.getters['shop/isShopEmpty']
        ) {
          await dialogService.openDialog(ShopActivationDialog);
        } else if (!from.meta.hasUnsavedComponents) {
          next();
        }
      } catch {
        next();
      }
    },
    beforeShopAreaLeave(to, from, next) {
      this.$store.commit('shop/validateShopSavableSubset', from.name);
      unsavedChangesService.triggerCheck({
        check: () => this.$store.getters['shop/shopSavableDirty'],
        save: () => {
          if (!this.$store.getters['shop/hasShopSavableValidationErrors']) {
            this.$store.dispatch('shop/saveShopSavable').then(() => {
              this.deactivatedShopReminder(to, from, next);
            });
          }
        },
        cancel: () => {
          this.$store.commit('shop/resetShopSavable');
          this.deactivatedShopReminder(to, from, next);
        },
        unchanged: () => {
          this.deactivatedShopReminder(to, from, next);
        },
      });
    },
    onNavigationUpdate(navigation) {
      this.customSaving = !!navigation?.customSaving;
    },
  },
  created() {
    this.init();
  },
  computed: {
    fullScreen() {
      return this.$store.state.shop.livePreview.fullScreen;
    },
    livePreviewVisible() {
      return this.$store.state.shop.livePreview.visible;
    },
    showShopSetupWizard() {
      // we check the server state here to prevent a race condition on saving the wizard changes.
      // (the live preview component must not be remounted after the changes have arrived on the server side.)
      return !this.$store.state.shop.shopSavable.originalData.settings
        .setupWizardDone;
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
  height: 0;
}
</style>
