<template>
  <nav
    class="navbar navbar-main navbar-default"
    role="navigation"
    :class="{ expanded: expanded }"
    v-click-outside="{ isActive: expanded, handler: onNavbarOutsideClick }"
  >
    <div class="navbar-header">
      <router-link to="/dashboard" @click="setNavbarExpansion(false)">
        <template v-if="partnerType === 'SHOP'">
          <SpreadshopLogo class="logo-wide" disabled />
          <SpreadshopLogo class="logo-small" :small="true" disabled />
        </template>
        <template v-else>
          <MarketplaceLogo class="logo-wide" disabled />
          <MarketplaceLogo class="logo-small" :small="true" disabled />
        </template>
      </router-link>
      <div v-if="isAdmin" class="ribbon-wrapper">
        <div class="ribbon">Admin</div>
      </div>
    </div>

    <div class="nav-items" id="navitems-container">
      <ul class="nav navbar-nav">
        <li :class="{ active: $route.name === 'partnerarea.dashboard' }">
          <router-link
            class="partnerarea-dashboard"
            to="/dashboard"
            @click="setNavbarExpansion(false)"
            v-tooltip.right="{
              content: !expanded ? $t('NAV.DASHBOARD') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="gauge" />
            <span class="nav-item-label">{{ $t('NAV.DASHBOARD') }}</span>
          </router-link>
        </li>
        <li
          :class="{
            active:
              $route.path.startsWith('/designs') ||
              ($route.name && $route.name.includes('partnerarea.idea')),
          }"
        >
          <a
            href
            class="partnerarea-ideas"
            @click.prevent="goToDesignsPage"
            v-tooltip.right="{
              content: !expanded ? $t('NAV.DESIGNS') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="shirt-design" />
            <span class="nav-item-label">{{ $t('NAV.DESIGNS') }}</span>
          </a>
        </li>
        <li
          v-if="publishingCoreEnabled"
          :class="{
            active:
              $route.path.startsWith('/publishing') ||
              ($route.name && $route.name.includes('partnerarea.publishing')),
          }"
        >
          <a
            href
            class="partnerarea-publishing"
            @click.prevent="goToPublishingPage"
            v-tooltip.right="{
              content: !expanded ? $t('Publishing') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="shirt-design" />
            <span class="nav-item-label">{{ $t('NAV.DESIGNS') }}</span>
          </a>
        </li>
        <li
          v-if="digitalProductsEnabled"
          :class="{
            active:
              $route.path.startsWith('/digitalProducts') ||
              ($route.name &&
                $route.name.includes('partnerarea.digitalProducts')),
          }"
        >
          <a
            href
            class="partnerarea-digital-products"
            @click.prevent="goToDigitalProductsPage"
            v-tooltip.right="{
              content: !expanded ? $t('NAV.DIGITAL_PRODUCTS') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="download-file" />
            <span class="nav-item-label">{{ $t('NAV.DIGITAL_PRODUCTS') }}</span>
          </a>
        </li>

        <li :class="{ active: $route.name === 'partnerarea.statistics' }">
          <router-link
            class="partnerarea-statistics"
            to="/statistics"
            @click="setNavbarExpansion(false)"
            v-tooltip.right="{
              content: !expanded ? $t('NAV.STATISTICS') : null,
              ...tooltipOptions,
            }"
            ><Icon class="nav-bar-icon" icon="statistics" />
            <span class="nav-item-label">{{ $t('NAV.STATISTICS') }}</span>
          </router-link>
        </li>
        <li
          :class="{
            active:
              $route.name && $route.name.includes('partnerarea.tipsAndTools'),
          }"
        >
          <a
            href
            class="partnerarea-tips-and-tools"
            @click.prevent="goToTipsAndToolsPage"
            v-tooltip.right="{
              content: !expanded ? $t('NAV.TIPS_AND_TOOLS') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="lightbulb" />
            <span class="nav-item-label">{{ $t('NAV.TIPS_AND_TOOLS') }}</span>
          </a>
        </li>
        <li
          :class="{
            active: $route.name === 'partnerarea.notifications',
          }"
        >
          <router-link
            class="partnerarea-notifications"
            to="/notifications"
            @click="setNavbarExpansion(false)"
            v-tooltip.right="{
              content: !expanded ? $t('NAV.NOTIFICATIONS') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="bell" />
            <span class="nav-item-label">{{ $t('NAV.NOTIFICATIONS') }}</span>
            <div class="alert">
              <NotificationsBadge />
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="pos">
      <span class="text-sm nav-item-label pos-label">{{ $t('NAV.POS') }}</span>
      <ul class="navbar-nav nav">
        <li
          v-if="showroomEnabled"
          class="showroom"
          :class="{ active: $route.name && $route.name.includes('showroom') }"
        >
          <a
            @click="goToShowroom"
            v-tooltip.right="{
              content: !expanded ? $t('GENERAL.SHOWROOM') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon" icon="sprd-heart" />
            <span class="nav-item-label">
              <small>{{ $t('GENERAL.MARKETPLACE') }}</small>
              {{ $t('GENERAL.SHOWROOM') }}
            </span>
          </a>
        </li>
        <li
          v-for="shop in multipleShops"
          :key="shop.name"
          :class="{
            active:
              $route.name &&
              $route.name.includes('shop-') &&
              $route.params.shopId === shop.target.id,
          }"
        >
          <a
            href="#"
            @click.prevent="goToShopSettings(shop)"
            class="shop"
            v-tooltip.right="{
              content: !expanded ? $t('GENERAL.SHOP') : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon shop-color" icon="shop" />
            <div class="nav-item-label">
              <small>{{ $t('GENERAL.SHOP') }}</small>
              <span class="text-truncate">{{ shop.name }}</span>
            </div>
          </a>
        </li>
        <li
          v-if="shops.length > 1"
          :class="{ active: $route.name && $route.name.includes('shop-') }"
        >
          <VDropdown
            placement="right"
            :triggers="['click']"
            theme="tooltip-navbar"
            :shown="shopSelectionOpen"
          >
            <a
              href="#"
              @click.prevent
              class="shop"
              v-tooltip.right="{
                content: !expanded ? $t('GENERAL.SHOPS') : null,
                ...tooltipOptions,
              }"
            >
              <Icon class="nav-bar-icon" icon="shop" />
              <div class="nav-item-label">
                {{ $t('GENERAL.SHOPS') }}
              </div>
              <Icon class="level2-link" icon="right" />
            </a>
            <template #popper>
              <ul>
                <li
                  v-for="shop in shops"
                  :key="shop.name"
                  :class="{
                    active:
                      $route.name &&
                      $route.name.includes('shop-') &&
                      $route.params.shopId === shop.target.id,
                  }"
                >
                  <a
                    v-close-popper
                    href="#"
                    @click.prevent="goToShopSettings(shop)"
                    class="shop"
                  >
                    <div class="nav-item-label">
                      <small>{{ $t('GENERAL.SHOP') }}</small>
                      <span class="text-truncate">{{ shop.name }}</span>
                    </div>
                  </a>
                </li>
              </ul>
            </template>
          </VDropdown>
        </li>
        <li class="openchannel">
          <a
            href="#"
            @click.prevent="openSalesChannelSelection()"
            class="partnerarea-openpos"
            v-tooltip.right="{
              content: !expanded
                ? $t(showroomEnabled ? 'NAV.OPEN_SHOP' : 'NAV.OPEN_POS')
                : null,
              ...tooltipOptions,
            }"
          >
            <Icon class="nav-bar-icon nav-bar-icon-small" icon="openchannel" />
            <span v-if="showroomEnabled" class="nav-item-label">{{
              $t('NAV.OPEN_SHOP')
            }}</span>
            <span v-if="!showroomEnabled" class="nav-item-label">{{
              $t('NAV.OPEN_POS')
            }}</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="navbar-nav nav">
      <li :class="{ active: $route.name === 'partnerarea.account' }">
        <router-link
          to="/account"
          @click="setNavbarExpansion(false)"
          class="account-link partnerarea-account"
          v-tooltip.right="{
            content: !expanded ? $t('NAV.ACCOUNT') : null,
            ...tooltipOptions,
          }"
          :data-account-id="userId"
        >
          <Icon class="nav-bar-icon" icon="account" />
          <span class="nav-item-label text-truncate">{{ nameToDisplay }}</span>
          <div class="alert" v-if="accountAlerts.length">
            <CircleBadge :color="accountAlerts[0].severity" />
          </div>
        </router-link>
      </li>
      <li>
        <a
          href="#"
          class="expand-link"
          @click.prevent="setNavbarExpansion(!expanded)"
        >
          <Icon class="nav-bar-icon" :icon="expanded ? 'collapse' : 'expand'" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import CircleBadge from 'src/app/components/badge/CircleBadge.vue';
import NotificationsBadge from 'src/app/partnerarea/notifications/NotificationsBadge.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import OpenSalesChannelDialog from 'src/app/partnerarea/pos/salesChannel/OpenSalesChannelDialog.vue';

export default {
  name: 'Navbar',
  components: {
    MarketplaceLogo,
    SpreadshopLogo,
    CircleBadge,
    NotificationsBadge,
  },
  data() {
    return {
      tooltipOptions: {
        delay: 400,
        theme: 'tooltip-dark',
      },
      shopSelectionOpen: false,
    };
  },
  computed: {
    ...mapState({
      expanded: (s) => s.application.navbarExpanded,
      userId: (s) => s.user.data.id,
      email: (s) => s.identity.email,
      username: (s) => s.identity.username,
    }),
    shops() {
      return this.$store.state.user.pointsOfSale.filter(
        (pos) => pos.type === 'SHOP'
      );
    },
    ...mapGetters({
      getSetting: 'settings/getSetting',
      accountAlerts: 'account/alerts',
      isAdmin: 'account/isAdmin',
      partnerType: 'user/partnerType',
      digitalProductsEnabled: 'settings/digitalProductsEnabled',
      publishingCoreEnabled: 'settings/publishingCoreEnabled',
    }),
    showroomEnabled() {
      return this.getSetting('MP_ENABLED');
    },
    nameToDisplay() {
      return this.username || this.email;
    },
    multipleShops() {
      return this.shops.length <= 1 ? this.shops : [];
    },
  },
  methods: {
    ...mapMutations({
      setNavbarExpansion: 'application/setNavbarExpansion',
    }),
    onNavbarOutsideClick() {
      this.setNavbarExpansion(false);
    },
    goToDesignsPage() {
      this.setNavbarExpansion(false);
      this.$router.push({ name: 'partnerarea.ideas' });
    },
    goToDigitalProductsPage() {
      this.setNavbarExpansion(false);
      this.$router.push({ name: 'partnerarea.digitalProducts' });
    },
    goToPublishingPage() {
      this.setNavbarExpansion(false);
      this.$router.push({ name: 'partnerarea.publishing' });
    },

    goToTipsAndToolsPage() {
      this.setNavbarExpansion(false);
      this.$router.push({ name: 'partnerarea.tipsAndTools' });
    },
    goToShowroom() {
      this.setNavbarExpansion(false);
      this.$router.push({ name: 'partnerarea.showroom' });
    },
    goToShopSettings(shop) {
      this.setNavbarExpansion(false);
      this.$router.push({
        name: 'shop-area',
        params: {
          shopId: shop.target.id,
          reload: true,
        },
      });
    },
    openSalesChannelSelection() {
      this.setNavbarExpansion(false);
      dialogService.openDialog(OpenSalesChannelDialog, null, {
        dialogContentClass: 'dialog-content-no-min-width',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';
@import 'src/scss/mixins';

.level2-link {
  position: absolute;
  top: 15px;
  right: 4px;
  width: 12px;
  height: 12px;
  transition: margin 0.1s linear;
}

.expanded .level2-link {
  margin-right: 5px;
}

.alert {
  position: absolute;
  right: 8px;
  top: 0;
  height: 100%;
  // width: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expanded .alert {
  right: 12px;

  .badge {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
}

.pos {
  flex-grow: 1;
  overflow: hidden;

  .nav-item-label {
    font-weight: bold;
  }
}

.pos-label {
  display: block !important;
  height: 16px;
  margin-left: 20px !important;
  margin-bottom: 8px;
  color: $grey40;
}

.expand-link {
  justify-content: center !important;
}

.navbar-header {
  padding: 24px 17px 80px 17px;

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 24px;
    // padding-left: 5px;
  }

  img {
    position: absolute;
  }

  .logo-small {
    display: block;
    width: auto;
    height: 24px;
  }
  .logo-wide {
    opacity: 0;
    width: 0;
  }
}

.expanded .navbar-header {
  .logo-wide {
    animation: show 0.1s linear forwards;
    animation-delay: 0.12s;
    width: auto;
    height: 24px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar-main {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-right: 2px solid $grey5;
  width: 65px;
  flex-shrink: 0;
  z-index: 1031;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.1s linear;

  .nav-item-label {
    display: none;
    opacity: 0;
    margin-left: 34px;
  }

  &.expanded {
    width: 250px;

    .nav-item-label {
      display: flex;
      animation: show 0.1s linear forwards;
      animation-delay: 0.12s;
    }

    .account-link .nav-item-label {
      display: block;
    }
  }

  .nav-items {
    flex-grow: 1;
    position: relative;
  }

  .navbar-nav ul,
  .navbar-nav li {
    float: none;
    padding: 0;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      background-color: transparent !important;
      color: $grey80;
      justify-content: flex-start;
      padding: 10px 20px;
      height: 44px;
      position: relative;

      &:not(.expand-link) {
        .nav-bar-icon {
          position: absolute;
          top: 10px;
          left: 20px;
        }
      }

      .nav-bar-icon-small {
        width: 20px;
        height: 20px;
        margin-left: 2px;
        top: 12px !important;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: $grey75;
      }
    }

    &.active {
      background-color: $grey5;
    }

    &.active,
    &:hover {
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        background-color: $pa-color-main;
      }
    }

    &.disabled {
      a {
        color: grey;
      }
    }
  }
}

:deep(.trigger) {
  display: block !important;
}
.ribbon-wrapper {
  left: 0px;
  position: absolute;
  top: 0px;
  height: 100px;
  width: 65px;
  overflow: hidden;
}

.ribbon {
  left: -80px;
  position: absolute;
  top: 10px;
  height: 24px;
  width: 206px;
  transform: rotate(-45deg);
  background-color: transparentize($pa-color-main, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.nav-bar-icon {
  display: block;
  width: 24px;
  height: 24px;
}

.showroom .nav-bar-icon {
  color: $mermaid;
}

.shop .nav-bar-icon {
  color: $pa-color-spreadshop;
}

.pos ul .nav-item-label,
.shop .nav-item-label {
  display: flex;
  flex-direction: column;
  @extend .text-default;

  small {
    color: $grey60;
    font-weight: normal;
  }
}

.shop .nav-item-label {
  max-width: 180px;
}

.openchannel {
  .icon {
    color: $grey60;
  }
}
</style>
