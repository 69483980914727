<template>
  <div class="sellable-list">
    <ul>
      <li v-for="sellable in sellables" :key="sellable.sellableId">
        <div class="image-wrapper">
          <SprdLazyImage
            :src="`${getSellableImage(sellable)},width=300,height=300.png`"
          />
        </div>
        <div class="footer">
          <p
            class="name"
            v-if="inventory && inventory.productTypes[sellable.productTypeId]"
          >
            {{ inventory.productTypes[sellable.productTypeId].name }}
          </p>
          <div v-if="!sellable.vpString" class="unavailable">
            <p>Product unavailable</p>
            <AdvancedPopover
              heading="Product Unavailable"
              :description="sellable.reason"
              :popoverTrigger="['click', 'touch']"
            >
              <button class="link link-red">Learn more</button>
            </AdvancedPopover>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SprdLazyImage from 'src/app/commons/image/SprdLazyImage.vue';
import compositionHelper from '@/composition/compositionHelper';
import localeService from '@/localeService/localeService';
import { getProductTypeImageUrl } from '@/image/imageUrlBuilder';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';

export default {
  name: 'SellableList',
  props: {
    sellables: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SprdLazyImage,
    AdvancedPopover,
  },
  data() {
    return {
      selectedCategory: null,
      locale: localeService.getLocale(),
    };
  },
  async created() {},
  computed: {
    ...mapState({
      inventory: (s) => s.publishingCore.translations,
    }),
  },
  methods: {
    getSellableImage(sellable) {
      return sellable.vpString
        ? compositionHelper.getProductImage(sellable)
        : getProductTypeImageUrl(sellable.productTypeId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';
.sellable-list {
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 16px;
    padding: 0 0 24px 0;
    margin: 0;

    li {
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid $grey10;
      display: flex;
      flex-direction: column;

      .image-wrapper {
        padding: 8px;
        background-color: $grey5;
        aspect-ratio: 1;
      }

      :deep(img) {
        width: 100%;
      }

      .footer {
        flex: 1;
        padding: 16px;
        border-top: 1px solid $grey10;
        min-height: 80px;
        display: flex;
        flex-direction: column;
      }

      .name {
        margin: 0;
      }

      .unavailable {
        margin-top: auto;
        padding-top: 8px;

        p {
          margin: 0;
          font-weight: bold;
          color: $pa-color-red;
        }

        button {
          font-weight: normal;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
