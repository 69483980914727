<template>
  <div class="sales">
    <div class="sales-list" ref="salesList">
      <StatisticsFilters
        heading="STATISTICS.SALES"
        @filterUpdate="onFilterUpdate"
        displayPosFilter
        displayDateFromFilter
        displayDateToFilter
        :flushBottom="salesSummary && !!salesSummary.length"
      />
      <SalesSummary :data="salesSummary" class="sales-summary" />
      <img
        class="sprd-loader loader"
        v-if="!salesByDay"
        src="/images/loader.svg"
        alt="loader"
      />
      <div class="list-content" v-if="salesByDay">
        <ul v-if="salesByDay.length">
          <li class="day" v-for="saleDay in salesByDay" :key="saleDay.day">
            <div class="day-label">
              {{ saleDay.day }} - {{ saleDay.sales.length }}
              {{ $t('STATISTICS.SALE_DETAILS.ORDERS') }}
            </div>
            <ul>
              <li
                class="sale"
                v-for="sale in saleDay.sales"
                :key="sale.orderId"
              >
                <div class="sale-label">
                  <div class="order-info">
                    {{ $t('STATISTICS.ORDER_NUMBER') }}:
                    <strong>{{ sale.orderId }}</strong>
                    - {{ sale.$amount }}
                    {{ $t('STATISTICS.SALE_DETAILS.ITEMS') }}
                  </div>
                  <div class="shipping">
                    {{ $t('STATISTICS.SALE_DETAILS.SHIPPING') }}:
                    <strong>{{ sale.$shippingCountry }}</strong>
                  </div>
                </div>
                <ul>
                  <li v-for="item in sale.items" :key="item.order.orderItemId">
                    <a
                      class="sale-item sales-status"
                      :class="`status-${item.combinedStatus}`"
                      href
                      @click.prevent="
                        openSaleDetails({ orderItemId: item.$data.orderItemId })
                      "
                    >
                      <div class="status">
                        <div>
                          <Icon
                            :icon="SALES_STATES[item.combinedStatus].icon"
                          ></Icon>
                          <strong>{{ item.$data.status }}</strong>
                        </div>
                      </div>
                      <SaleItemImage
                        :image="item.images[0].url"
                        :type="item.images[0].type"
                        :width="130"
                        :height="130"
                        :requestProps="{ width: 150, height: 150 }"
                      />
                      <div class="pos">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.POS')
                          }}</small>
                          <strong>{{ item.$data.pointOfSale }}</strong>
                        </div>
                      </div>
                      <div class="amount">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.QUANTITY')
                          }}</small>
                          <strong>{{ item.$data.amount }}</strong>
                        </div>
                      </div>
                      <div class="price">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.PRICE')
                          }}</small>
                          <strong> {{ item.$data.price }} </strong>
                        </div>
                      </div>
                      <div class="commission">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.COMMISSION')
                          }}</small>
                          <strong>{{ item.$data.commission }}</strong>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="empty-message" v-if="!salesByDay.length">
          <Icon icon="x"></Icon>
          <strong>{{ $t('STATISTICS.CREDIT_DETAILS.NO_RESULTS') }}</strong>
        </div>
        <div class="pagination-wrapper" v-if="salesByDay.length">
          <div class="export">
            <a
              :href="downloadLink"
              class="link-main"
              download="sales.csv"
              target="_self"
            >
              <Icon icon="download-file"></Icon>
              {{ $t('STATISTICS.DOWNLOAD_CSV') }}
            </a>
          </div>
          <Pagination
            v-if="metaData.count > pagination.pageSize"
            :total="metaData.count"
            :pageSize="pagination.pageSize"
            :currentPage="pagination.currentPage"
            addStateParam
            @pageChange="onPageChange"
          ></Pagination>

          <div class="scroll" v-if="saleItemCount > 5">
            <a href="#" @click.prevent="scrollToTop()" class="link-main">
              <Icon class="rotate-icon-270" icon="arrow-end"></Icon>
              {{ $t('STATISTICS.TO_TOP') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format';
import parse from 'date-fns/parseISO';
import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import { mapGetters } from 'vuex';

import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import date from 'src/app/commons/date/date';
import Pagination from 'src/app/commons/pagination/Pagination.vue';
import SaleItemImage from './saleImage/SaleItemImage.vue';
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';
import SalesSummary from './SalesSummary.vue';
import StatisticsFilters from '../StatisticsFilters.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import SaleDetailsDialog from 'src/app/partnerarea/statistics/sales/saleDetailsDialog/SaleDetailsDialog.vue';

export default {
  name: 'Sales',
  components: {
    Pagination,
    SaleItemImage,
    SalesSummary,
    StatisticsFilters,
  },
  data() {
    return {
      SALES_STATES: statisticsService.getSaleStates(),
      pagination: {
        pageSize: 20,
        currentPage: 0,
        maxSize: 5,
      },
      metaData: {
        count: 0,
        limit: 0,
        offset: 0,
        listSize: 0,
      },
      filters: null,
      salesByDay: null,
      saleItemCount: null,
      downloadLink: null,
      salesSummary: null,
    };
  },
  created() {
    if (this.$route.query.page && this.$route.query.page >= 1) {
      this.pagination.currentPage = this.$route.query.page - 1;
    }
  },
  computed: {
    ...mapGetters({
      shippingCountryByIso: 'platform/shippingCountryByIso',
    }),
  },
  methods: {
    async fetchSalesData() {
      this.salesByDay = null;

      this.fetchSalesSummary();

      const data = await statisticsService.getSales(
        this.getOptions({ pagination: true })
      );

      this.metaData = {
        count: data.count,
        limit: data.limit,
        offset: data.offset,
        listSize: data.list.length,
      };
      this.salesByDay = this.prepareSalesData(data.list);
      this.saleItemCount = data.list.reduce(
        (prev, cur) => prev + cur.items.length,
        0
      );
      this.downloadLink = statisticsService.getSalesDetailsDownloadUrl(
        this.getOptions()
      );

      if (!this.salesByDay.length && this.pagination.currentPage > 0) {
        this.onPageChange(0);
      }

      return data;
    },
    async fetchSalesSummary() {
      this.salesSummary = null;

      const data = await statisticsService.getSalesSummary(
        this.getOptions({ pagination: true })
      );
      this.salesSummary = data.salesSummaries;
    },
    getOptions(opts = {}) {
      const options = {
        dateFrom: this.filters.dateFrom
          ? startOfDay(parseISO(this.filters.dateFrom))
          : null,
        dateTo: this.filters.dateTo
          ? endOfDay(parseISO(this.filters.dateTo))
          : null,
        pos: this.filters.pointOfSale.value,
      };

      if (opts.pagination) {
        options.limit = this.pagination.pageSize;
        options.offset = this.pagination.currentPage;
      }

      return options;
    },
    prepareSalesData(sales) {
      const result = [];

      const data = sales.reduce((result, current) => {
        const group = `${format(parse(current.saleDate), 'yyyy-MM-dd')}`;
        if (!result[group]) {
          result[group] = [];
        }

        current.$shippingCountry = this.shippingCountryByIso(
          current.shippingCountry
        );
        current.$amount = current.items.reduce(
          (res, curr) => res + curr.salesAmount,
          0
        );

        current.items.forEach((item) => {
          item.$data = {
            day: date(group, 'longDate'),
            status: this.$t(
              `STATISTICS.SALE_DETAILS.STATUS.${
                this.SALES_STATES[item.combinedStatus].key
              }.NAME`
            ),
            pointOfSale: pointOfSaleName(item.pointOfSale),
            amount: this.sprdNumber(item.salesAmount),
            price: this.sprdCurrency(
              item.price.totalPrice,
              item.price.priceCurrencyId
            ),
            commission: this.sprdCurrency(
              item.commission.totalCommission,
              item.commission.commissionCurrencyId
            ),
            orderItemId: item.order.orderItemId,
          };
        });

        result[group].push(current);
        return result;
      }, {});
      Object.keys(data).forEach((day) => {
        result.push({
          day: date(day, 'longDate'),
          sales: data[day],
        });
      });

      return result;
    },
    onFilterUpdate(filters, { initial }) {
      this.filters = filters;

      if (!initial) {
        this.pagination.currentPage = 0;
      }
      this.fetchSalesData();
    },
    openSaleDetails(data) {
      dialogService.openDialog(SaleDetailsDialog, data);
    },
    async onPageChange(newPage) {
      this.pagination.currentPage = newPage;
      await this.fetchSalesData();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.salesList.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.sales {
  width: 100%;
  margin: 0 auto;

  .sales-summary {
    border-radius: 0 0 4px 4px;
  }

  .sales-list {
    max-width: 1400px;

    .loader {
      width: 100%;
      height: 120px;
      margin-top: 48px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .day-label {
    padding: 0 0 8px 0;
    margin-top: 25px;
    border-bottom: 1px solid $sprd-color-light-grey;
    font-weight: bold;
    @extend .text-xxl;
  }

  .day:first-child .day-label {
    margin-top: 0;
  }

  .sale-label {
    padding: 0 0 8px 0;
    margin-top: 16px;
    display: flex;

    .shipping {
      margin-left: auto;
    }
  }

  .sale ul {
    li {
      background-color: $white;

      &:first-child {
        border-radius: 4px 4px 0 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:nth-child(even) {
        background-color: #f8f8f8;
      }

      &:hover {
        transform: scale(1);
        box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.24);
      }
    }
  }

  .sale-item {
    display: flex;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .status {
      width: 10%;
      min-width: 120px;
      border-right: 1px solid $grey20;

      & > div {
        align-items: center;
      }

      .icon {
        font-size: 2.2em;
        margin-bottom: 5px;
      }

      strong {
        text-align: center;
      }
    }

    .pos {
      width: 20%;

      span {
        text-align: center;
      }
    }

    .amount {
      width: 15%;
    }

    .price {
      width: 20%;
    }

    .commission {
      width: 20%;
    }

    .cell-heading {
      margin-bottom: 5px;
      @extend .text-xs;
    }
  }

  .pagination-wrapper {
    background-color: transparent;
    border-top: 1px solid $sprd-color-light-grey;
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';

.sales-status {
  position: relative;

  &.status-NEW .status {
    color: $pa-color-main;
  }

  &.status-SENT_AND_PAID .status {
    color: $pa-color-green;
  }

  &.status-COMMISSION_PAID .status {
    color: $pa-color-green;
  }

  &.status-PROGRESS .status {
    color: $grey60;
  }

  &.status-CANCEL .status {
    color: $pa-color-red;
  }

  &.status-RETURN .status {
    color: $pa-color-red;
  }

  &.status-SENT .status {
    color: $pa-color-green;
  }

  &.status-CHARGED_BACK .status {
    color: $pa-color-red;
  }

  &.status-WAIT .status {
    color: $grey60;
  }
}
</style>
