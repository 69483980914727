<template>
  <div class="publishing-category-breadcrumb">
    <button @click="resetSelectedCategory">All Products</button>
    <SellableCategoryTreeLevel
      :categoryLevel="categories"
      :selectedCategory="selectedCategory"
      @selectCategory="selectCategory"
      breadCrumbOnly
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SellableCategoryTreeLevel from './SellableCategoryTreeLevel.vue';

export default {
  name: 'SellableCategoryBreadcrumb',
  props: {
    selectedCategory: {
      type: Object,
    },
  },
  components: {
    SellableCategoryTreeLevel,
  },
  async created() {},
  computed: {
    ...mapState({
      categories: (s) => s.publishingCore.categories,
    }),
  },
  methods: {
    selectCategory(category) {
      this.$emit('selectCategory', category);
    },
    resetSelectedCategory() {
      this.$emit('selectCategory', null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';
@import 'src/scss/styleguide/type';

.publishing-category-breadcrumb {
  display: flex;
  align-items: center;

  & > button {
    padding: 0;
  }

  :deep(.icon) {
    margin: 0 8px;
  }

  :deep(button) {
    @extend .text-sm;
  }
}
</style>
