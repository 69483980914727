<template>
  <div class="notifications">
    <div class="header">
      <h3>
        {{ $t('NOTIFICATIONS.UNREAD') }}
      </h3>
      <NotificationsBadge />
      <router-link to="/notifications" class="link-main">{{
        $t('GENERAL.VIEW_ALL')
      }}</router-link>
    </div>
    <div class="notificationlist">
      <TransitionGroup v-if="unreadCount" name="fade" tag="ul">
        <li
          v-for="notification in notificationList"
          :key="notification.notificationId"
        >
          <NotificationItem :notification="notification" />
        </li>
      </TransitionGroup>
      <div v-else class="empty">
        <p class="text-grey">{{ $t('NOTIFICATIONS.NO_UNREAD') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationItem from 'src/app/partnerarea/notifications/NotificationItem.vue';
import NotificationsBadge from 'src/app/partnerarea/notifications/NotificationsBadge.vue';
import { notificationTopics } from '@/api/notifications/notificationTopics';

export default {
  name: 'NotificationsModule',
  components: {
    NotificationItem,
    NotificationsBadge,
  },
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      feedItems: null,
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/unread',
      unreadCount: 'notifications/unreadCount',
    }),
    notificationList() {
      return this.notifications
        .slice()
        .sort((a, b) => {
          if (
            a.topic !== notificationTopics.ANNOUNCEMENTS.key &&
            b.topic === notificationTopics.ANNOUNCEMENTS.key
          ) {
            return 1;
          } else if (
            a.topic === notificationTopics.ANNOUNCEMENTS.key &&
            b.topic !== notificationTopics.ANNOUNCEMENTS.key
          ) {
            return -1;
          } else {
            return 0;
          }
        })
        .slice(0, this.count);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/utils';

.notifications {
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey5;
    padding-bottom: 16px;

    h3 {
      display: block;
      margin: 0;
    }

    .badge {
      width: 24px;
      height: 24px;
      font-size: 11px;
      margin-left: 8px;
      margin-right: 8px;
    }

    a {
      margin-left: auto;
    }
  }
  ul {
    list-style: none;
    padding: 16px 24px;
    margin: 0 -24px -24px -24px;

    li:nth-child(n + 2) {
      display: none;
    }

    li:not(:first-child) {
      border-top: solid 2px $grey5;
    }
  }

  .empty {
    p {
      margin: 24px 0 0 0;
    }
  }
}
</style>
