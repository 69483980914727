<template>
  <div class="account-nav-bottom" v-if="userData && data">
    <img class="profile-image" :src="profileImage" alt="profile image" />
    <p>{{ username || userData.id }}</p>
    <div
      v-tooltip.top-start="{
        content: isAdmin ? 'not allowed as admin' : null,
        hideOnTargetClick: false,
      }"
    >
      <button
        :disabled="isAdmin"
        @click="openEditDialog"
        class="btn btn-light icon-btn"
      >
        <Icon icon="pencil" />
      </button>
    </div>

    <router-link
      to="/logout"
      class="btn btn-light icon-btn logout"
      v-trackClickEvent="{ name: 'logout' }"
    >
      <Icon icon="logout" />
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import dialogService from '@/dialogs/wrapper/dialogService';
import UserNameAndImageDialog from './UserNameAndImageDialog.vue';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';

export default {
  name: 'AccountNavBottom',
  components: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.query?.editProfile) {
      this.openEditDialog();
    }
  },
  computed: {
    ...mapState({
      userData: (s) => s.user.data,
      username: (s) => s.identity.username,
      data: (state) => state.account.current,
    }),
    ...mapGetters({
      isAdmin: 'account/isAdmin',
      profileImage: 'user/profileImage',
    }),
  },
  methods: {
    async openEditDialog() {
      const noUnsavedChanges = await unsavedChangesService.onRouteUpdate(
        {},
        {}
      );
      if (noUnsavedChanges) {
        try {
          await dialogService.openDialog(UserNameAndImageDialog);
        } catch (error) {
          this.$store.commit('account/revertChanges');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.account-nav-bottom {
  padding: 0 24px;
  display: flex;
  align-items: center;
}

p {
  font-weight: bold;
  margin: 0 auto 0 8px;
  @extend .text-truncate;
}
.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.btn {
  aspect-ratio: 1;
  margin-left: 8px;
  padding: 8px;
  border-width: 1px;
  border-radius: 4px;
  .icon {
    margin: 0;
    width: 16px;
    height: 16px;
  }

  &.logout {
    color: $pa-color-red;
    border-color: $pa-color-red;
  }
}
</style>
