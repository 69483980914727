<template>
  <div class="publishing-hotspot-selection">
    <div class="header-text">
      <h4>Default Position</h4>
      <p>Select your preferred design placement on apparel.</p>
    </div>
    <ul>
      <li v-for="hotspot in hotspots" :key="hotspot">
        <button
          class="btn"
          :class="{ active: hotspot === currentHotspot }"
          @click="selectHotspot(hotspot)"
        >
          {{ hotspot }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import compositionHelper from '@/composition/compositionHelper';

export default {
  name: 'SellableHotspotSelection',
  props: {
    composition: {
      type: Object,
    },
  },
  async created() {},
  computed: {
    ...mapState({
      categories: (s) => s.publishingCore.categories,
    }),
    hotspots() {
      return compositionCategoryHelper.getAllAvailableHotspots(this.categories);
    },
    currentHotspot() {
      return compositionHelper.getDefaultHotspot(this.composition);
    },
  },
  methods: {
    selectHotspot(hotspot) {
      this.$emit('onSelect', hotspot);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';
@import 'src/scss/styleguide/type';

.publishing-hotspot-selection {
  .header-text {
    h4 {
      margin: 0;
    }

    p {
      margin: 8px 0 0 0;
      color: $grey60;
    }
  }
  ul {
    list-style: none;
    margin: 16px 0 0 0;
    padding: 0;
    display: flex;

    li {
      button {
        border-radius: 22px;
        border: 1px solid $grey30;
        font-weight: normal;
        color: $grey90;
        margin: 0 16px 0 0;
        height: 40px;

        &:hover,
        &.active {
          border-color: $pa-color-main;
          color: $pa-color-main;
        }
      }
    }
  }
}
</style>
