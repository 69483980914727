<template>
  <Banner
    class="banner"
    icon="lightbulb"
    heading="GRAPHICS_SERVICE.HEADING"
    subheading="GRAPHICS_SERVICE.EXPLANATION"
    link="GRAPHICS_SERVICE.LINK"
    @onLinkClick="goToGraphicsService"
    :inverted="inverted"
    :condensed="condensed"
    :linkIcon="linkIcon"
  >
    <template #left>
      <div v-if="condensed"></div>
    </template>
  </Banner>
</template>

<script>
import { mapGetters } from 'vuex';
import localeService from '@/localeService/localeService';
import Banner from './Banner.vue';
import graphicsServiceLinks from '@/externalLinks/graphicsServiceLinks';

export default {
  name: 'GraphicsServiceBanner',
  props: {
    inverted: {
      type: Boolean,
    },
    condensed: {
      type: Boolean,
    },
    linkIcon: {
      type: String,
    },
  },
  components: {
    Banner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
    }),
  },
  async created() {},
  methods: {
    goToGraphicsService() {
      // const partnerType = this.hasMarketplace ? 'MP' : 'SHOP';

      // no spreadshop graphics service for now, always link to mp variant
      const partnerType = 'MP';
      const links = graphicsServiceLinks[partnerType][SPRD.PLATFORM];

      window.open(links[localeService.getLocale()] || links.default, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin: 24px 0;

  :deep(a) {
    min-width: 150px;
  }
}
</style>
