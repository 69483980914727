<template>
  <div class="sellable-default-value-control">
    <div class="tabs">
      <button
        v-for="mode in MODES"
        :key="mode.key"
        class="link"
        :class="{ active: mode.key === currentMode.key }"
        @click="selectMode(mode)"
      >
        {{ mode.name }}
      </button>
    </div>
    <div class="tab-content">
      <component
        :is="currentMode.component"
        :composition="composition"
        @onSelect="(arg) => $emit(currentMode.selectionEmitName, arg)"
      />
    </div>
  </div>
</template>

<script>
import SellableHotspotSelection from './SellableHotspotSelection.vue';
import SellableColorSelection from './SellableColorSelection.vue';

const MODES = [
  {
    key: 'HOTSPOT',
    name: 'Position',
    description: 'Select your preferred design placement on apparel.',
    component: SellableHotspotSelection,
    selectionEmitName: 'onSelectHotspot',
  },
  {
    key: 'COLOR',
    name: 'Color',
    description: 'Colors with successful automated contrast checks.',
    component: SellableColorSelection,
    selectionEmitName: 'onSelectColor',
  },
];

export default {
  name: 'SellableDefaultValueControl',
  props: {
    composition: {
      type: Object,
    },
  },
  components: {
    SellableHotspotSelection,
    SellableColorSelection,
  },
  data() {
    return {
      MODES,
      currentMode: MODES[0],
    };
  },
  methods: {
    selectMode(mode) {
      this.currentMode = mode;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.sellable-default-value-control {
  .tabs {
    border-bottom: 1px solid $grey15;
    padding: 0 48px;

    button {
      margin: 0 16px 0 0;
      padding: 8px;
      border-bottom: 2px solid transparent;
      font-weight: normal;
      color: $grey65;

      &.active {
        border-color: $pa-color-main;
        color: $grey90;
      }
    }
  }

  .tab-content {
    padding: 24px 48px;
  }
}
</style>
