<template>
  <table class="data-table">
    <tr>
      <th>
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.TRANSLATION_LANGUAGES'
          )
        }}
      </th>
      <th>{{ $t('GENERAL.STATUS') }}</th>
      <th>{{ $t('SA.shopsettings.information.url.label') }}</th>
    </tr>
    <tr v-for="language in languagesToDisplay" :key="language.isoCode">
      <td>{{ language.name }}</td>
      <td class="text-grey">
        <span v-if="language.pending">{{ $t(pendingStatusText) }}</span>
        <span v-else>{{ $t(onlineStatusKey) }}</span>
      </td>
      <td class="country-links">
        <a
          v-for="deeplink in language.deeplinks"
          :key="deeplink.url"
          :href="deeplink.url"
          target="_blank"
          :disabled="!deeplink.url"
          class="link-main"
          :title="deeplink.countryName"
        >
          <img :src="`images/flags/${deeplink.country}.svg`" alt="" />
        </a>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import INTPUB_DECISION_STATES from '@/states/intpubDecisionStates';
import localeService from 'src/app/commons/localeService/localeService';

export default {
  name: 'InternationalPublishingStatusList',
  props: {
    getDeeplinkForLocale: {
      required: true,
      type: Function,
    },
    requestDeeplinkForPendingTranslations: {
      type: Boolean,
    },
    displayNoneExistingTranslations: {
      type: Boolean,
    },
    displayUnavailableDeeplinks: {
      type: Boolean,
    },
    pendingStatusText: {
      type: String,
      default: 'DESIGNS.STATUS.REVIEW',
    },
    includeEurope: {
      type: Boolean,
    },
  },
  data() {
    return {
      addedTranslations: null,
    };
  },
  async created() {
    this.addedTranslations = this.languages
      .filter((language) => {
        if (
          this.displayNoneExistingTranslations ||
          (this.requestedAutotranslations &&
            this.intPubDecision !== INTPUB_DECISION_STATES.REJECTED)
        ) {
          return true;
        } else {
          return this.idea.translations.some(
            (ideaTranslation) => ideaTranslation.locale === language.isoCode
          );
        }
      })
      .map((language) => ({
        isoCode: language.isoCode,
        name: this.languageByIso(language.isoCode).name,
        deeplinks: [],
        pending: !this.idea.translations.find(
          (translation) => translation.locale === language.isoCode
        ),
      }))
      .sort((x, y) =>
        x.isoCode === this.defaultLanguage
          ? -1
          : y.isoCode === this.defaultLanguage
          ? 1
          : 0
      );

    this.addedTranslations.map((translation) =>
      this.fetchDeeplinks(translation)
    );
  },
  computed: {
    ...mapGetters({
      defaultLanguage: 'user/language',
      requestedAutotranslations: 'idea/requestedAutotranslations',
      intPubDecision: 'idea/intPubDecision',
      languageByIso: 'platform/languageByIso',
    }),
    ...mapState({
      idea: (state) => state.idea.current,
      languages: (state) => state.platform.languages,
      countries: (state) => state.platform.countries,
    }),
    onlineStatusKey() {
      return this.idea.approvalPending
        ? 'DESIGNS.STATUS.ONLINE_FIRST'
        : 'DESIGNS.STATUS.PUBLISHED';
    },
    languagesToDisplay() {
      return this.addedTranslations.filter(
        (translation) =>
          this.displayUnavailableDeeplinks || translation.deeplinks.length
      );
    },
  },
  methods: {
    async fetchDeeplinks(language) {
      const localeInformations = localeService.getLocaleInformation();

      if (this.includeEurope) {
        localeInformations.push({
          locale: 'en_EU',
        });
      }

      const localeData = localeInformations
        .filter(
          (localeInformation) =>
            localeService.getLanguageFromLocale(localeInformation.locale) ===
            language.isoCode
        )
        .map((localeInformation) => ({
          locale: localeInformation.locale,
          country: localeService
            .getCountryFromLocale(localeInformation.locale)
            .toLowerCase(),
        }))
        .map((localeInformation) => ({
          ...localeInformation,
          countryName: this.countries.find(
            (country) =>
              country.isoCode === localeInformation.country.toUpperCase()
          )?.name,
        }));

      language.deeplinks = localeData;

      if (language.pending && !this.requestDeeplinkForPendingTranslations) {
        return;
      }

      try {
        const data = await Promise.all(
          localeData.map(({ locale }) => this.getDeeplinkForLocale(locale))
        );

        if (data?.length) {
          language.deeplinks = localeData
            .map((localeEntry) => ({
              ...localeEntry,
              url: data.find((entry) => entry.locale === localeEntry.locale)
                .url,
            }))
            .filter(
              (deeplink) => this.displayUnavailableDeeplinks || deeplink.url
            );
        }
      } catch (error) {
        console.error('design not available on pos', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.country-links {
  a {
    margin-left: 8px;

    img {
      width: 20px;
    }

    &[disabled] img {
      opacity: 0.5;
    }

    &:not([disabled]):hover img {
      box-shadow: 0 0 4px 0px rgb(0 0 0 / 23%);
      transform: scale(1.3);
    }
  }
}
</style>
