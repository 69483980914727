import collectionSaving from '../collections/details/collectionSaving';

export default {
  url: 'shop-area.staticShopPreview',
  label: 'SA.navigation.editShop.label',
  children: [
    { type: 'ctn' },
    {
      url: 'shop-area.general',
      label: 'POS.SHOP_SETTINGS.NAVIGATION.GENERAL.HEADING',
      children: [
        { type: 'ctn' },
        {
          url: 'shop-area.general.seo',
          label: 'SA.navigation.seo.label',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.general.imprint',
          label: 'SA.shop-area.basicSettings.imprint.label',
          subheading: 'SA.imprint.description',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.general.shop-status',
          label: 'SA.navigation.shopStatus.label',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.SHOPSTATUS.SUBHEADING',
          children: [
            {
              type: 'ctn',
              entryClass: 'entry--max',
            },
          ],
        },
      ],
    },
    {
      url: 'shop-area.pages',
      label: 'POS.SHOP_SETTINGS.NAVIGATION.PAGES.HEADING',
      children: [
        {
          url: 'shop-area.pages.start-page',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.HEADING',
          subheading: 'POS.SHOP_SETTINGS.STARTPAGE.SUBHEADING',
          children: [
            { type: 'ctn' },
            {
              url: 'shop-area.pages.start-page.teaser',
              label: 'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.TEASER.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.collection-preview',
              label:
                'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.COLLECTION_PREVIEW.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.product-preview',
              label:
                'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.PRODUCT_PREVIEW.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.about-us',
              label: 'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.ABOUT_US.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.trust-elements',
              label:
                'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.TRUST_ELEMENTS.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.promotion',
              label: 'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.PROMO.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.product-type-category',
              label:
                'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.PRODUCT_RANGE.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.follow-us',
              label: 'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.FOLLOW_US.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.cyo-intro',
              label: 'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.CYO_INTRO.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.design-preview',
              label:
                'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.DESIGN_PREVIEW.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
            {
              url: 'shop-area.pages.start-page.designs-with-products',
              label:
                'POS.SHOP_SETTINGS.NAVIGATION.STARTPAGE.DESIGNS_WITH_PRODUCTS.HEADING',
              hideNavEntry: true,
              children: [{ type: 'ctn' }],
            },
          ],
          className: 'start-page',
        },
        {
          url: 'shop-area.pages.product-list',
          label: 'SA.navigation.productList.label',
          subheading: 'SA.navigation.productList.description',
          children: [{ type: 'ctn' }],
          className: 'product-list-entry',
        },
        {
          url: 'shop-area.pages.detail-page',
          label: 'SA.navigation.detailPage.label',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.DETAILPAGE.SUBHEADING',
          children: [{ type: 'ctn' }],
          className: 'detail-page-entry',
        },
        {
          url: 'shop-area.pages.designer',
          label: 'SA.navigation.designer.label',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.DESIGNER.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.pages.collections',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.COLLECTIONS.LIST.HEADING',
          subheading:
            'POS.SHOP_SETTINGS.NAVIGATION.COLLECTIONS.LIST.SUBHEADING',
          children: [
            { type: 'ctn' },
            {
              url: 'shop-area.pages.collections-detail',
              label: 'POS.SHOP_SETTINGS.NAVIGATION.COLLECTIONS.DETAIL.HEADING',
              subheading:
                'POS.SHOP_SETTINGS.NAVIGATION.COLLECTIONS.DETAIL.SUBHEADING',
              hideNavEntry: true,
              customSaving: {
                save: collectionSaving.save,
                reset: collectionSaving.reset,
                dirty: collectionSaving.dirty,
              },
              children: [{ type: 'ctn' }],
            },
          ],
        },
        {
          url: 'shop-area.pages.about-us-page',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.ABOUT_US.LIST.HEADING',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.ABOUT_US.LIST.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
      ],
    },
    {
      url: 'shop-area.design',
      label: 'POS.SHOP_SETTINGS.NAVIGATION.DESIGN.HEADING',
      children: [
        {
          url: 'shop-area.design.header-logo',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.HEADING',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.design.shop-styles',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.DESIGN.SHOP_STYLES.HEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.design.templates',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.DESIGN.TEMPLATES.HEADING',
          subheading:
            'POS.SHOP_SETTINGS.NAVIGATION.DESIGN.TEMPLATES.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.design.promotion-appearance',
          label:
            'POS.SHOP_SETTINGS.NAVIGATION.GENERAL.PROMOTION_APPEARANCE.HEADING',
          subheading:
            'POS.SHOP_SETTINGS.NAVIGATION.GENERAL.PROMOTION_APPEARANCE.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        { type: 'spacer' },
        {
          url: 'shop-area.design.model-images',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.GENERAL.MODELIMGS.HEADING',
          subheading:
            'POS.SHOP_SETTINGS.NAVIGATION.GENERAL.MODELIMGS.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        { type: 'ctn' },
      ],
    },
    {
      url: 'shop-area.pricing-and-promos',
      label: 'POS.SHOP_SETTINGS.NAVIGATION.PRICING_AND_PROMO.HEADING',
      children: [
        {
          url: 'shop-area.pricing-and-promos.pricing',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.PRICING.HEADING',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.PRICING.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.pricing-and-promos.non-profit',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.NONPROFIT.HEADING',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.NONPROFIT.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.pricing-and-promos.promos',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.PROMOTIONS.HEADING',
          subheading: 'POS.SHOP_SETTINGS.NAVIGATION.PROMOTIONS.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        { type: 'ctn' },
      ],
    },
    {
      url: 'shop-area.youtubeMerchShelf',
      label: 'POS.SHOP_SETTINGS.NAVIGATION.YOUTUBE_MERCH_SHELF.HEADING',
      subheading: 'POS.SHOP_SETTINGS.YOUTUBE_MERCH_SHELF.DESCRIPTION',
      children: [{ type: 'ctn' }],
      isEnabled: (currentShop) => currentShop.youtubeConnected,
    },
    {
      url: 'shop-area.marketing',
      label: 'POS.SHOP_SETTINGS.NAVIGATION.MARKETING.HEADING',
      children: [
        {
          url: 'shop-area.marketing.social-media-tool',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.SOCIAL_MEDIA_TOOL.HEADING',
          subheading:
            'POS.SHOP_SETTINGS.NAVIGATION.SOCIAL_MEDIA_TOOL.SUBHEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.marketing.google',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.GOOGLE_MKT.HEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.marketing.facebook',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.FACEBOOK_MKT.HEADING',
          subheading: 'POS.SHOP_SETTINGS.FACEBOOK_MKT.DESCRIPTION',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.marketing.pinterest',
          label: 'POS.SHOP_SETTINGS.NAVIGATION.PINTEREST_MKT.HEADING',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.marketing.tiktok',
          label: 'TikTok',
          children: [{ type: 'ctn' }],
        },
      ],
    },
    {
      url: 'shop-area.advanced',
      label: 'SA.navigation.advanced.label',
      children: [
        {
          url: 'shop-area.advanced.advanced-html-css',
          label: 'SA.navigation.htmlCSS.label',
          subheading: 'SA.navigation.htmlCSS.subheading',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.advanced.integration',
          label: 'SA.navigation.integration.label',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.advanced.header-social-media-profile',
          label: 'SA.navigation.socialMedia.title',
          subheading: 'POS.SHOP_SETTINGS.SOCIAL_ICONS.DESCRIPTION',
          children: [{ type: 'ctn' }],
        },
        {
          url: 'shop-area.advanced.language',
          label: 'SA.shopsettings.international.label',
          children: [{ type: 'ctn' }],
          updateShopDataOnSave: true,
        },
      ],
    },
  ],
};
