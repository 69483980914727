<template>
  <div class="dialog-size-md shop-selection-dialog">
    <h1>{{ $t(data.heading) }}</h1>
    <p>{{ $t(data.subheading) }}</p>
    <div>
      <Checkbox
        :model-value="allShopsSelected"
        @update:model-value="setAllShopsSelected"
        >All Shops</Checkbox
      >
    </div>
    <ul>
      <li v-for="shop in shopList" :key="shop.id">
        <Checkbox v-model="shop.selected"
          ><p class="name">{{ shop.shopName }}</p></Checkbox
        >
        <Badge :type="shop.hidden ? 'error' : 'success'" class="active-state">{{
          $t(shop.hidden ? 'GENERAL.INACTIVE' : 'GENERAL.ACTIVE')
        }}</Badge>
      </li>
    </ul>
    <div class="actions">
      <button class="btn btn-primary" @click="apply">
        {{ $t('GENERAL.APPLY') }}
      </button>
      <button class="btn btn-ghost" @click="modalInstance.dismiss">
        {{ $t('GENERAL.CANCEL') }}
      </button>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/checkbox/Checkbox.vue';
import Badge from '../badge/Badge.vue';

export default {
  name: 'ShopSelectionDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  components: {
    Checkbox,
    Badge,
  },
  data() {
    return {
      shopList: null,
    };
  },
  computed: {
    allShopsSelected() {
      return !this.shopList.some((shop) => !shop.selected);
    },
  },
  created() {
    this.shopList = this.data.shops.map((shop) => ({
      ...shop,
      selected: this.data.selectedShopIds
        ?.map((shopId) => parseInt(shopId))
        .includes(parseInt(shop.id)),
    }));
  },
  methods: {
    setAllShopsSelected(selected) {
      this.shopList.forEach((shop) => (shop.selected = selected));
    },
    apply() {
      this.modalInstance.close(
        this.shopList.filter((shop) => shop.selected).map((shop) => shop.id)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.shop-selection-dialog {
  & > h1 {
    margin: 0;
  }

  & > p {
    color: $grey65;
    margin: 8px 0 32px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 8px 0 0 24px;

    li {
      padding: 16px 0;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid $grey10;
      }
    }
  }

  .name {
    margin: 0;
  }

  .active-state {
    margin: 0 0 0 auto;
  }

  .actions {
    margin: 16px 0 0 0;
    display: flex;
    gap: 8px;

    .btn {
      flex: 1;
    }
  }
}
</style>
