<template>
  <Badge :type="status.badgeType">
    <template v-if="!hideIcon">
      <Icon
        v-for="posType in posTypes"
        :key="posType.key"
        :icon="posType.icon"
      />
    </template>

    {{ status.name }}
  </Badge>
</template>

<script>
import Badge from '../../badge/Badge.vue';

export default {
  name: 'CompositionPosStatusBadge',
  components: {
    Badge,
  },
  props: {
    posTypes: {
      required: true,
      type: Array,
    },
    status: {
      required: true,
      type: Object,
    },
    hideIcon: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
