<template>
  <div class="dialog-size-md shop-international-publishing-status-dialog">
    <div class="modal-body">
      <h2>
        {{ $t('DESIGNS.DETAILS.SHOP_INTPUB.HEADING') }}
      </h2>
      <p class="text-grey">
        {{ $t('DESIGNS.DETAILS.SHOP_INTPUB.SUBHEADING') }}
      </p>

      <div v-if="isNationalShopWithTranslations" class="color-box">
        {{
          $t(
            'DESIGNS.DETAILS.SHOP_INTPUB.HINTS.NATIONAL_SHOP_WITH_TRANSLATIONS'
          )
        }}

        <a
          href="#"
          @click.prevent="goToShopLanguageSettings"
          class="link-white"
          >{{
            $t('DESIGNS.DETAILS.SHOP_INTPUB.HINTS.GO_TO_SHOP_LANGUAGE_SETTINGS')
          }}</a
        >
      </div>
      <div v-if="isInternationalShopWithoutTranslations" class="color-box">
        {{
          $t(
            'DESIGNS.DETAILS.SHOP_INTPUB.HINTS.INTERNATIONAL_SHOP_WITHOUT_TRANSLATIONS'
          )
        }}
      </div>

      <InternationalPublishingStatusTable
        v-if="deeplinks"
        :getDeeplinkForLocale="getDeeplinkForLocale"
        pendingStatusText="DESIGNS.DETAILS.SHOP_INTPUB.DEFAULT_FALLBACK"
        requestDeeplinkForPendingTranslations
        displayNoneExistingTranslations
        includeEurope
      />
    </div>
  </div>
</template>

<script>
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import { mapState, mapGetters } from 'vuex';
import InternationalPublishingStatusTable from './InternationalPublishingStatusTable.vue';
import INTPUB_DECISION_STATES from '@/states/intpubDecisionStates';
import localeService from '@/localeService/localeService';

export default {
  name: 'ShopInternationalPublishingStatusDialog',
  props: ['modalInstance', 'data'],
  components: {
    InternationalPublishingStatusTable,
  },
  data() {
    return {
      deeplinks: null,
    };
  },
  async created() {
    const deeplinks = await this.fetchDeeplinks();
    this.deeplinks = deeplinks;
  },
  computed: {
    ...mapGetters({
      requestedAutotranslations: 'idea/requestedAutotranslations',
      intPubDecision: 'idea/intPubDecision',
    }),
    ...mapState({
      idea: (state) => state.idea.current,
    }),
    isNationalShopWithTranslations() {
      return (
        this.deeplinks &&
        Object.keys(this.deeplinks).length <= 2 &&
        this.requestedAutotranslations &&
        this.intPubDecision !== INTPUB_DECISION_STATES.REJECTED
      );
    },
    isInternationalShopWithoutTranslations() {
      return (
        this.deeplinks &&
        Object.keys(this.deeplinks).length > 2 &&
        !this.requestedAutotranslations &&
        this.intPubDecision !== INTPUB_DECISION_STATES.REJECTED
      );
    },
  },
  methods: {
    async getDeeplinkForLocale(locale) {
      return {
        locale,
        url:
          this.deeplinks[locale] ||
          this.deeplinks[localeService.convertToSprdLocale(locale)],
      };
    },
    async fetchDeeplinks() {
      const res = await shopAPI.getShopDeeplinkForIdea(
        this.data.shopId,
        this.idea.id
      );
      return res.data;
    },
    goToShopLanguageSettings() {
      this.$router.push({
        name: 'shop-area.advanced.language',
        params: {
          shopId: this.data.shopId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.shop-international-publishing-status-dialog {
  .modal-body {
    & > h2 {
      margin: 0;
    }

    & > p {
      margin: 12px 0 16px 0;
    }
  }
}

.loading-wrapper {
  margin: 48px 0;
}
</style>
