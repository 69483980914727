<template>
  <div class="publishing-meta-data">
    <ConfigList>
      <ul>
        <li>
          <div>Default Language</div>
          <button
            class="link language-link"
            @click="openDefaultTranslationDialog"
          >
            {{ languageName }} <Icon icon="right" />
          </button>
        </li>
        <li>
          <div>Automatic language translation</div>
          <ToggleSwitch
            :model-value="composition.metaData.autoTranslate"
            @update:model-value="
              (autoTranslate) => setAutoTranslate({ autoTranslate })
            "
          />
        </li>
        <MpTransformerModeControl
          class="internal-feature"
          :mpTransformerMode="
            composition.publishingDetails.d2c.mpTransformerMode
          "
          @setMpTransformerMode="setMpTransformerMode"
          style="margin-left: auto"
        />
        <li>
          <div class="meta-data-content">
            <div>
              <LabelInput :class="{ 'has-error': nameError }">
                <label for="name">Name</label>
                <input
                  type="text"
                  id="name"
                  :value="metaData.name.value"
                  @input="changeName"
                  :maxlength="validatorOptions.name.max"
                />
                <div class="field-info">
                  <small class="error-info error-info-name" v-if="!validName">
                    {{
                      $t('DESIGNS.VALIDATION.NAME.LENGTH', {
                        min: validatorOptions.name.min,
                        max: validatorOptions.name.max,
                      })
                    }}
                  </small>
                  <small v-else class="info">
                    Displayed as design headline, a title line in Google search
                    results and in the URL of your design.
                  </small>
                  <small class="char-info design-description-chars-left"
                    >{{ metaData.name.value.length || 0 }} /
                    {{ validatorOptions.name.max }}</small
                  >
                </div>
              </LabelInput>
            </div>
            <div>
              <LabelInput
                :class="{ 'has-error': validation.errors.description }"
              >
                <label for="description">Description</label>
                <textarea
                  name="description"
                  :maxlength="1500"
                  :value="metaData.description.value"
                  @input="changeDescription"
                  rows="3"
                  spellcheck="true"
                />
                <div class="field-info">
                  <small class="info">
                    It appears in the link previews on Google, Facebook and
                    other channels as well as in "Design details" on your sales
                    channels.
                  </small>
                  <small class="char-info design-description-chars-left"
                    >{{ metaData.description.value.length || 0 }} / 1500</small
                  >
                </div>
              </LabelInput>
            </div>
            <div>
              <CompositionTagInput
                :tags="metaData.tags.values"
                :composition="composition"
                @addTag="onAddTag"
                @removeTag="onRemoveTag"
                :displayTagsLeft="true"
                :displayRelatedTags="false"
                :displayErrorText="true"
                :language="currentLocale"
                :displayHint="true"
                :validate="validate"
              />
            </div>
          </div>
        </li>
      </ul>
    </ConfigList>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import ConfigList from 'src/app/components/publishing/ConfigList.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import compositionHelper from '@/composition/compositionHelper';
import CompositionTagInput from '@/tagInput/CompositionTagInput.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import PublishingDefaultTranslationDialog from './PublishingDefaultTranslationDialog.vue';
import MpTransformerModeControl from 'src/app/components/publishing/MpTransformerModeControl.vue';
import compositionValidator from '@/api/validators/composition/compositionValidator';

export default {
  name: 'PublishingMetaData',
  components: {
    ToggleSwitch,
    ConfigList,
    LabelInput,
    CompositionTagInput,
    MpTransformerModeControl,
  },
  props: {
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validatorOptions: compositionValidator.getDefaultOptions(),
      validation: {
        errors: {},
      },
    };
  },
  created() {},
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({
      languageByIso: 'platform/languageByIso',
    }),
    metaData() {
      return compositionHelper.getDefaultMetaData(this.composition);
    },
    currentLocale() {
      return this.composition.metaData.originLanguageCode;
    },
    languageName() {
      return this.languageByIso(this.composition.metaData.originLanguageCode)
        ?.name;
    },
    validName() {
      if (!this.validate) {
        return true;
      }

      return compositionValidator.validateName(this.composition, {
        locale: this.currentLocale,
      });
    },
    nameError() {
      return !this.validName;
    },
    validDescription() {
      if (!this.validate) {
        return true;
      }

      return compositionValidator.validateDescription(this.composition, {
        locale: this.currentLocale,
      });
    },
    descriptionError() {
      return !this.validDescription;
    },
  },
  methods: {
    ...mapMutations({
      setName: 'composition/setName',
      setDescription: 'composition/setDescription',
      addTag: 'composition/addTag',
      removeTag: 'composition/removeTag',
      setAutoTranslate: 'composition/setAutoTranslate',
      setMpTransformerMode: 'composition/setMpTransformerMode',
    }),
    ...mapActions({}),
    changeName: debounce(function (evt) {
      this.setName({ name: evt.target.value });
    }, 200),
    changeDescription: debounce(function (evt) {
      this.setDescription({ description: evt.target.value });
    }, 200),
    onAddTag(tag) {
      this.addTag({ tag });
    },
    onRemoveTag(tag) {
      this.removeTag({ tag });
    },
    openDefaultTranslationDialog() {
      dialogService.openDialog(PublishingDefaultTranslationDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.meta-data-content {
  width: 100%;

  & > div {
    padding: 16px 0 8px 0;
  }
}

.language-link {
  font-weight: normal;
  color: $grey80;
  .icon {
    margin-left: 8px;
  }
}

.internal-feature {
  padding: 16px 0 0 0;
}
</style>
