<template>
  <div class="publishing-create-from-design">
    <div class="header">
      <h1>Create from design</h1>
      <p>Choose a design</p>
    </div>
    <div class="design-list">
      <ul v-if="!loading && designs.length">
        <li v-for="design in designs" :key="design.id">
          <Tile
            :name="design.name"
            @onClick="() => designSelected(design)"
            icon="download-file"
            clickable
            :image="design.resources[0].href"
            imageOverlayIcon="plus"
            class="design-tile"
            :displayStatus="false"
          />
        </li>
      </ul>
      <LoadingHeart v-else />
    </div>
  </div>
</template>

<script>
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import designService from '@/api/designService/designService';
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import Tile from '@/tileView/Tile.vue';

export default {
  name: 'PublishingCreateFromDesign',
  props: {},
  components: {
    Tile,
    LoadingHeart,
  },
  data() {
    return {
      loading: true,
      designs: [],
      count: null,
      limit: 20,
      offset: 0,
    };
  },
  async created() {
    await this.fetchDesigns();
  },
  computed: {},
  watch: {},
  methods: {
    async fetchDesigns() {
      const res = await designService.getDesigns({
        limit: this.limit,
        offset: this.offset,
      });
      this.designs = res.designs;
      this.count = res.count;
      this.loading = false;
    },
    async designSelected(design) {
      await publishingCoreService.createCompositionFromDesignId(design.id);
      this.$emit('onDone');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.publishing-create-from-design {
  min-width: 700px;
  .header {
    text-align: center;
    margin: 0 0 16px 0;

    h1 {
      margin: 0 0 16px 0;
    }

    p {
      margin: 0;
      color: $grey60;
    }
  }

  .design-list {
    background-color: $grey5;
    padding: 24px;
    border-radius: 8px;

    ul {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: 24px;
      padding: 0 0 24px 0;
      margin: 0;
    }
  }
}
</style>
