<template>
  <div
    class="livePreview"
    :class="{ invisible: !visible, fullScreen: fullScreen }"
  >
    <div class="iframe-scaler" :class="mode">
      <div class="iframe-wrapper">
        <div class="sizeable" :class="mode">
          <div class="iframePage">
            <LoadingHeart v-if="loading" />
            <iframe
              v-if="url"
              :src="url"
              @load="onIframeLoaded"
              ref="iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import { validateEverything } from 'src/app/commons/store/validators/shopSavableValidator';
import debounce from 'lodash.debounce';
import { deepCopy } from 'src/app/commons/utils';

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  for (const prop in value) {
    if (Object.prototype.hasOwnProperty.call(value, prop)) {
      return false;
    }
  }

  return true;
};

const objKeys = (obj) => (obj ? Object.keys(obj) : []);

const needsSavablePost = (newSavable, oldSavable) => {
  if (objKeys(newSavable).length !== objKeys(oldSavable).length) {
    // a new section was bootstrapped. no user interaction so far => we do not need to post anything.
    return false;
  }
  // the number of keys in a section only changes when the initial bootstrapping succeeds. we do not want to refresh when that happens.
  const subKeysChanged = objKeys(newSavable).some(
    (sectionKey) =>
      objKeys(newSavable[sectionKey]).length !==
      objKeys(oldSavable[sectionKey]).length
  );
  return !subKeysChanged;
};

export default {
  name: 'ShopLivePreview',
  components: {
    LoadingHeart,
  },
  data() {
    return {
      loading: true,
      url: this.$store.getters['shop/getSpreadshopUrl'](
        this.$store.state.shop.livePreview.path,
        true
      ),
      previousSavable: deepCopy(this.$store.state.shop.shopSavable.clientData),
    };
  },
  created() {
    // when the shop changes, change the iframe url, thus hard-refreshing the iframe
    this.$store.watch(
      (state) => state.shop.currentShop,
      () =>
        (this.url = this.$store.getters['shop/getSpreadshopUrl'](
          this.$store.state.shop.livePreview.path,
          true
        ))
    );

    // when only the currently visible page changes, post a message to the iframe and spreadshop will pick it up from there
    this.$store.watch(
      (state) => state.shop.livePreview,
      (newValue, oldValue) => {
        if (
          (newValue.path !== oldValue.path ||
            newValue.section !== oldValue.section) &&
          this.$refs.iframe
        ) {
          this.$refs.iframe.contentWindow.postMessage(
            {
              type: 'scrollToSectionCommand',
              path: `/${newValue.path}`,
              target: newValue.section,
            },
            '*'
          );
        }
      }
    );

    // when the settings change, post them to spreadshop in a message so they can be live-previewed
    this.$store.watch(
      (state) => state.shop.shopSavable.clientData,
      (newValue) => {
        if (needsSavablePost(newValue, this.previousSavable)) {
          this.updateLivePreview(newValue);
        }
        this.previousSavable = deepCopy(newValue);
      },
      {
        deep: true,
      }
    );
  },
  computed: {
    mode() {
      return this.$store.state.shop.livePreview.mode;
    },
    visible() {
      return this.$store.state.shop.livePreview.visible;
    },
    fullScreen() {
      return this.$store.state.shop.livePreview.fullScreen;
    },
  },
  methods: {
    onIframeLoaded() {
      this.loading = false;
    },
    updateLivePreview: debounce(function (livePreviewData) {
      if (this.$refs.iframe && validateEverything(livePreviewData).result) {
        const { settings, channels, collections, startPage, aboutPage } =
          livePreviewData;
        const postMsg = {
          settings: isEmpty(settings) ? null : settings,
          social: isEmpty(channels) ? null : channels,
          collectionOrder:
            isEmpty(collections) || isEmpty(collections.collectionList)
              ? null
              : collections.collectionList.map((collection) => collection.id),
          startPage: isEmpty(startPage) ? null : startPage,
          aboutPage: isEmpty(aboutPage) ? null : aboutPage,
          type: 'shopSettings',
        };
        this.$refs.iframe.contentWindow.postMessage(
          JSON.parse(JSON.stringify(postMsg)),
          '*'
        );
      }
    }, 2000),
  },
};
</script>

<style lang="scss" scoped>
.invisible {
  display: none;
}

.livePreview {
  background-color: #fff;
  border-right: none;
  border-top: none;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  box-shadow: 0px 0px 12px #0000003b;

  .top-bar {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(247, 247, 247, 0.9);
    z-index: 10;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      text-decoration: none;

      .icon {
        width: 25px;
        height: 25px;
      }
    }

    i {
      padding-right: 10px;
      font-size: 30px;
    }
  }

  &.ng-hide-add {
    animation: preview-out 1s;
  }

  &.ng-hide-remove {
    animation: preview-in 1s;

    .iframe-wrapper {
      animation: wrapper-in 1s;
    }
  }

  .iframe-scaler {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.mobile {
      .iframe-wrapper {
        top: 20px;
        @media (max-height: 1100px) {
          transform: scale(0.9) translateY(-40px);
        }

        @media (max-height: 900px) {
          transform: scale(0.8) translateY(-80px);
        }

        @media (max-height: 770px) {
          transform: scale(0.7) translateY(-130px);
        }

        @media (max-height: 670px) {
          transform: scale(0.6) translateY(-170px);
        }
      }
    }

    &.tablet {
      .iframe-wrapper {
        top: 20px;
        @media (max-height: 770px) {
          transform: scale(0.8) translateY(-40px);
        }

        @media (max-height: 670px) {
          transform: scale(0.7) translateY(-100px);
        }
      }
    }
  }

  .iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;

    .sizeable {
      height: 100%;
      margin: auto;
      background-repeat: round;
      transition: margin 400ms ease, padding 400ms ease;

      &.desktop {
        width: 100%;
        height: 100%;
      }

      &.tablet {
        background-image: url('/images/tablet-landscape.png');
        background-repeat: no-repeat;
        width: 1000px;
        height: 640px;
        margin: auto;
        padding: 30px 160px 30px 30px;
      }

      &.mobile {
        background-image: url('/images/mobile.png');
        background-repeat: no-repeat;
        width: 416px;
        height: 787px;
        margin: auto;
        padding: 60px 15px 140px 15px;
      }

      iframe {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
      }
    }
  }

  @keyframes wrapper-in {
    0% {
      opacity: 0;
      display: none;
    }
    50% {
      opacity: 0;
      display: none;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes preview-in {
    0% {
      width: calc(100vw - (100px + 25%));
      left: calc(100px + 25%);
      opacity: 0;
    }
    50% {
      width: calc(100vw - (100px + 25%));
      left: calc(100px + 25%);
      opacity: 1;
    }
    100% {
      left: 0;
    }
  }

  @keyframes preview-out {
    100% {
      width: calc(100vw - (100px + 25%));
      left: calc(100px + 25%);
      opacity: 0;
    }
    50% {
      width: calc(100vw - (100px + 25%));
      left: calc(100px + 25%);
      opacity: 1;
    }
    0% {
      left: 0;
      opacity: 1;
    }
  }
}

.iframePage {
  height: 100%;
  display: block;

  & > iframe {
    z-index: 1;
  }
}

.fullScreen {
  position: relative;
  box-shadow: none;
}
</style>
