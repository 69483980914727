<template>
  <ul
    class="publishing-category-tree-level"
    :class="`depth-${depth} ${breadCrumbOnly ? 'breadCrumbOnly' : ''}`"
  >
    <li
      v-for="category in categoryLevel.children"
      :key="category.id"
      :class="{
        selected: isSelected(category),
        childSelected: isChildSelected(category),
      }"
    >
      <Icon v-if="breadCrumbOnly" icon="right" />
      <button @click="() => selectCategory(category)" class="name">
        {{ category.names[locale] }}
      </button>
      <div
        v-if="
          category.children &&
          (isSelected(category) || isChildSelected(category))
        "
        class="sublevel"
      >
        <SellableCategoryTreeLevel
          :selectedCategory="selectedCategory"
          :categoryLevel="category"
          @selectCategory="selectCategory"
          :depth="depth + 1"
          :breadCrumbOnly="breadCrumbOnly"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import localeService from '@/localeService/localeService';

export default {
  name: 'SellableCategoryTreeLevel',
  props: {
    selectedCategory: {
      type: Object,
    },
    categoryLevel: {
      type: Object,
    },
    depth: {
      type: Number,
      default: 0,
    },
    breadCrumbOnly: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      locale: localeService.getLocale(),
    };
  },
  async created() {},
  computed: {
    ...mapState({
      categories: (s) => s.publishingCore.categories,
    }),
  },
  methods: {
    selectCategory(category) {
      this.$emit('selectCategory', category);
    },
    isSelected(category) {
      return category.id === this.selectedCategory?.id;
    },
    isChildSelected(category) {
      if (this.selectedCategory) {
        return compositionCategoryHelper.isCategoryInsideCategoryLevel(
          category,
          this.selectedCategory
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';
@import 'src/scss/styleguide/type';

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 16px 0 0 0;
  &.selected {
    & > button {
      color: $pa-color-main;
    }

    button {
      font-weight: bold;
    }
  }

  &.childSelected {
    & > button {
      font-weight: bold;
    }
  }

  .sublevel {
    margin: 0 0 0 16px;
  }
}

button {
  padding: 0;
}

.name {
  text-align: left;
}

:not(.breadCrumbOnly) .depth-0 > li:first-child {
  margin: 0;
}

:not(.breadCrumbOnly).depth-0 > li > button {
  @extend .text-xl;
}

ul.breadCrumbOnly {
  li {
    display: none;
  }

  .sublevel {
    margin: 0;
  }

  li.childSelected {
    display: flex;
    margin: 0;
    align-items: center;

    & > button {
      font-weight: normal;
    }
  }

  li.selected > button {
    color: $grey80;
    font-weight: bold;
  }
}
</style>
