<template>
  <div class="onboarding" v-if="display">
    <div class="checklist-wrapper">
      <h3>{{ $t('ONBOARD.CHECKLIST.HEADING') }}</h3>
      <small>{{ $t('ONBOARD.CHECKLIST.SUBHEADING') }}</small>
      <OnboardingChecklist />
    </div>
    <div class="help">
      <h3>{{ $t('ONBOARD.CHECKLIST.HELP_LINKS.HEADING') }}</h3>
      <small>{{ $t('ONBOARD.CHECKLIST.HELP_LINKS.SUBHEADING') }}</small>
      <OnboardingHelpCards light />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OnboardingChecklist from 'src/app/components/onboarding/OnboardingChecklist.vue';
import OnboardingHelpCards from 'src/app/components/onboarding/OnboardingHelpCards.vue';

export default {
  name: 'OnboardingModule',
  components: {
    OnboardingChecklist,
    OnboardingHelpCards,
  },
  computed: {
    ...mapState({
      initialized: (s) => s.onboarding.initialized,
    }),
    ...mapGetters({
      unfinishedSteps: 'onboarding/unfinishedRelevantSteps',
    }),
    display() {
      return (
        this.initialized &&
        this.unfinishedSteps.steps &&
        this.unfinishedSteps.steps.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';

.onboarding {
  display: flex;
  padding: 24px;
  background-color: $grey0;
  border-radius: 8px;
  margin-bottom: 24px;

  h3 {
    margin: 0 0 8px 0;
  }

  small {
    display: block;
    color: $grey60;
    margin: 0 0 16px 0;
  }
}

.checklist-wrapper {
  width: 50%;
  margin-right: 12px;
}

.help {
  background-color: $grey5;
  border-radius: 8px;
  margin-left: 12px;
  width: 50%;
  padding: 16px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include small-desktop {
    padding: 16px;
  }
}
</style>
