<template>
  <GlossaryList
    heading="STATISTICS.SALE_DETAILS.STATUS_EXPLANATION"
    :glossary="states"
  />
</template>

<script>
import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import GlossaryList from 'src/app/partnerarea/idea/list/statusGlossary/GlossaryList.vue';

export default {
  name: 'SalesStatusDialog',
  components: { GlossaryList },
  props: ['modalInstance', 'data'],
  data() {
    return {
      SALE_STATES: statisticsService.getSaleStates(),
    };
  },
  computed: {
    states() {
      return Object.values(this.SALE_STATES).map((status) => ({
        name: `${this.getCopyKey(status.key)}.NAME`,
        description: `${this.getCopyKey(status.key)}.DESCRIPTION`,
        class: `sales-status status-${status.key}`,
        icon: status.icon,
      }));
    },
  },
  methods: {
    getCopyKey(name) {
      return `STATISTICS.SALE_DETAILS.STATUS.${name}`;
    },
  },
};
</script>
