<template>
  <div class="publishing-category-tree">
    <SellableCategoryTreeLevel
      :categoryLevel="categories"
      :selectedCategory="selectedCategory"
      @selectCategory="selectCategory"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SellableCategoryTreeLevel from './SellableCategoryTreeLevel.vue';

export default {
  name: 'SellableCategoryTree',
  props: {
    selectedCategory: {
      type: Object,
    },
  },
  components: {
    SellableCategoryTreeLevel,
  },
  async created() {},
  computed: {
    ...mapState({
      categories: (s) => s.publishingCore.categories,
    }),
  },
  methods: {
    selectCategory(category) {
      this.$emit('selectCategory', category);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.publishing-category-tree {
  & > button {
    padding: 0;
  }
}
</style>
